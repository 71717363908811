import { IUserLogin } from 'interfaces/UserInterfaces';
import { AxiosResponse } from 'axios';
import API from './api';

export const login = (
  userId: string,
  password: string
): Promise<AxiosResponse<IUserLogin>> => {
  return API.post('/password-check', {
    userId,
    password,
  });
};

export const recoverPassword = (
  userId: string,
  email: string
): Promise<AxiosResponse<IUserLogin>> => {
  return API.post('/password-check', {
    userId,
    email,
  });
};
