/* eslint-disable @typescript-eslint/no-explicit-any */
import { INetworkResponse } from 'interfaces/index';
import { IPresetItem, SAVED_REPORT } from 'interfaces/ReportInterfaces';
import { deleteSavedReport, getSavedReports } from 'services/AdHocReports';

interface IHomeNetwork {
  savedPresets: (userId: string) => Promise<INetworkResponse<IPresetItem[]>>;
  deletePreset: (savedReport: IPresetItem) => Promise<INetworkResponse<string>>;
}

const savedPresets: (
  userId: string
) => Promise<INetworkResponse<IPresetItem[]>> = async (
  userId
): Promise<INetworkResponse<IPresetItem[]>> => {
  try {
    const savedReportResults = await getSavedReports(userId);
    const presetItems: IPresetItem[] = savedReportResults.data.map((result) => {
      return {
        id: result.Id ?? 0,
        name: result.Title,
        type: SAVED_REPORT,
      };
    });
    return {
      success: savedReportResults.status === 200,
      statusCode: savedReportResults.status,
      errorMessage: '',
      payload: presetItems,
    };
  } catch (error: any) {
    console.log(error);
    return {
      success: false,
      errorMessage: 'Generic error!',
      statusCode: error.code,
      payload: [],
    };
  }
};

const deletePreset: (
  savedReport: IPresetItem
) => Promise<INetworkResponse<string>> = async (
  savedReport
): Promise<INetworkResponse<string>> => {
  try {
    if (savedReport.id) {
      const result = await deleteSavedReport(savedReport.id);
      return {
        success: result.status === 204,
        errorMessage: '',
        statusCode: result.status,
        payload: `${savedReport.name} deleted successfully`,
      };
    }
    return {
      success: false,
      errorMessage: 'No report Id',
      statusCode: 200,
      payload: `${savedReport.name} was not deleted`,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.code,
      errorMessage: '',
      payload: `${savedReport.name} could not be deleted`,
    };
  }
};

const HomeNetwork: IHomeNetwork = {
  savedPresets,
  deletePreset,
};

export default HomeNetwork;
