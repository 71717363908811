import * as actions from 'actions/modalActions';
import { REPORT_PRESETS, USAGE_PER_TAB } from 'interfaces/ReportInterfaces';

export interface IDownloadModalPayload {
  presetType: REPORT_PRESETS;
  presetName: string;
  presetId?: number;
}

export type IDownloadModalState = IDownloadModalPayload & {
  isOpen: boolean;
  loading: boolean;
};

export const initialState = (): IDownloadModalState => ({
  isOpen: false,
  presetType: USAGE_PER_TAB,
  presetName: '',
  loading: false,
});

export const downloadModalReducer = (
  state: IDownloadModalState,
  action: actions.DownloadModalAction
): IDownloadModalState => {
  switch (action.type) {
    case actions.OPEN_DMODAL:
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    case actions.CLOSE_DMODAL:
      return initialState();
    case actions.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actions.FINISH_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};
