import { AxiosResponse } from 'axios';
import {
  IHierarchyItem,
  IHierarchyUploadResponse,
} from 'interfaces/HierarchyInterfaces';
import API from './api';

export const uploadHierarchy = (
  hierarchyItems: IHierarchyItem[]
): Promise<AxiosResponse<IHierarchyUploadResponse>> => {
  return API.post('/hierarchy', {
    hierarchyItems,
  });
};

export const downloadHierarchy = (): Promise<
  AxiosResponse<IHierarchyItem[]>
> => {
  return API.get('/hierarchy');
};
