import { LayoutPosition } from 'chart.js';
import {
  IPDFTable,
  ITBDGraph,
  ITBDGraphData,
} from 'interfaces/ReportInterfaces';
import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

interface ITBDReportGraphs {
  reportTableData: IPDFTable[];
}

const TBDReportGraphs: React.FC<ITBDReportGraphs> = ({ reportTableData }) => {
  const [graphs, setGraphs] = React.useState<ITBDGraph[]>([]);
  const colors = [
    '#449ED6',
    '#9DBBC7',
    '#d6c41e',
    '#d48a22',
    '#0057B8',
    '#c00e49',
    '#15b800',
  ];

  useEffect(() => {
    const tbdGraphs: ITBDGraph[] = reportTableData.map<ITBDGraph>(
      ({ tableData, tableDescription }) => {
        const retailerCol = tableData.headers.findIndex(
          (header) => header === 'Retailer'
        );
        const eventCol = tableData.headers.findIndex(
          (header) => header === 'EventData'
        );
        const dataCol = tableData.headers.findIndex(
          (header) => header !== 'Retailer' && header !== 'EventData'
        );

        const labels = new Set(tableData.rows.map((row) => row[retailerCol]));
        const sets =
          eventCol !== -1
            ? new Set(tableData.rows.map((row) => row[eventCol]))
            : new Set([]);

        const graphData: ITBDGraphData = {};

        if (tableData.headers.length > 2 && eventCol !== -1) {
          tableData.rows.forEach((row) => {
            if (!graphData[row[retailerCol]])
              graphData[row[retailerCol]] = new Map();

            graphData[row[retailerCol]].set(row[eventCol], row[dataCol]);
          });
        } else {
          tableData.rows.forEach((row, idx) => {
            if (!graphData[row[retailerCol]])
              graphData[row[retailerCol]] = new Map();

            graphData[row[retailerCol]].set(idx.toString(), row[dataCol]);
          });
        }

        return {
          title: tableDescription.title,
          labels,
          sets,
          graph: graphData,
        };
      }
    );

    setGraphs(tbdGraphs);
  });

  return (
    <>
      {graphs.map((graphData) => {
        const options = {
          plugins: {
            title: {
              display: true,
              text: graphData.title,
            },
            legend: {
              display: graphData.sets.size > 0,
              position: 'right' as LayoutPosition,
            },
          },
        };

        const data = {
          labels: Array.from(graphData.labels),
          datasets:
            graphData.sets.size > 0
              ? Array.from(graphData.sets).map((set, idx) => ({
                  label: set,
                  data: Array.from(graphData.labels).map(
                    (label) => graphData.graph[label].get(set) || '0'
                  ),
                  backgroundColor: colors[idx],
                }))
              : [
                  {
                    label: 'Value',
                    data: Array.from(graphData.labels).map(
                      (label) => Array.from(graphData.graph[label].values())[0]
                    ),
                    backgroundColor: '#0057B8',
                  },
                ],
        };

        return <Bar options={options} data={data} />;
      })}
    </>
  );
};

export default TBDReportGraphs;
