import { IHierarchyItem } from 'interfaces/HierarchyInterfaces';
import XLSX from 'xlsx';
import { IHierarchyBusiness, IHierarchyUploadResponse } from './interfaces';
import HierarchyNetwork from './HierarchyNetwork';

const useHierarchyBusiness = (): IHierarchyBusiness => {
  const uploadFile = async (file: File): Promise<IHierarchyUploadResponse> => {
    const data = await file?.arrayBuffer();
    const wb = XLSX.read(data, { type: 'binary' });

    const sheetName = wb.SheetNames[0];
    const sheet = wb.Sheets[sheetName];
    const items = XLSX.utils.sheet_to_json<IHierarchyItem>(sheet);

    const response = await HierarchyNetwork.handleUpload(items);

    return { success: response.success };
  };

  const downloadFile = async () => {
    const response = await HierarchyNetwork.handleDownload();

    if (response.success === false) {
      return false;
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(response.payload);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'hierarchy.xlsx');

    return true;
  };

  const loginFormBusinessObj: IHierarchyBusiness = {
    uploadFile,
    downloadFile,
  };

  return loginFormBusinessObj;
};

export default useHierarchyBusiness;
