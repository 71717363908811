import React from 'react';
import { Flex, Text, TextProps } from '@chakra-ui/react';
import attLogo from 'assets/images/attGlobeBlue.png';
import { useTranslation } from 'react-i18next';

const textProps: TextProps = {
  fontSize: '8px',
  lineHeight: '10.47px',
  fontWeight: '400',
  fontFamily: 'ATT Aleck Sans',
};

const PDFFooter: React.FC = () => {
  const { t } = useTranslation('footer');

  return (
    <Flex alignItems="center" padding="5px" marginTop="6px">
      <Flex direction="column" flex={7}>
        <Text {...textProps}>{t('directv')}</Text>
        <Text {...textProps}>{t('attAndGlobo')}</Text>
      </Flex>
      <Flex flex={1} direction="column">
        <img
          style={{ alignSelf: 'flex-end', width: '24px', height: '24px' }}
          alt=""
          src={attLogo}
        />
      </Flex>
    </Flex>
  );
};

export default PDFFooter;
