import { Button, Flex } from '@chakra-ui/react';
import RangeDatePicker from 'components/RangeDatePicker';
import { TimePeriodType } from 'interfaces/ReportInterfaces';
import React from 'react';
import useBuildReportViewModel from './BuildReportViewModel';

type ITimeButton = {
  label: string;
  type: TimePeriodType;
  onClick: () => void;
};

const TimeButton: React.FC<ITimeButton> = ({ label, type, onClick }) => {
  const { timePeriod } = useBuildReportViewModel();

  return (
    <Button
      variant={timePeriod === type ? 'solid' : 'ghost'}
      size="xs"
      padding={4}
      mr={4}
      fontSize="14px"
      fontWeight="bold"
      borderRadius="full"
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

const TimePeriod: React.FC = () => {
  const {
    startDate,
    endDate,
    handleLastWeek,
    handleLastMonthPreset,
    handleMonthToDatePreset,
    handleThisYearPreset,
    handleChangeStartDate,
    handleChangeEndDate,
  } = useBuildReportViewModel();

  return (
    <Flex w="100%" mt="0.6875rem" justifyContent="space-between">
      <TimeButton
        label="Last 7 days"
        type="last7Days"
        onClick={handleLastWeek}
      />
      <TimeButton
        label="Last 4 weeks"
        type="last4Weeks"
        onClick={handleLastMonthPreset}
      />
      <TimeButton
        label="Month to date"
        type="monthToDate"
        onClick={handleMonthToDatePreset}
      />
      <TimeButton
        label="This year"
        type="thisYear"
        onClick={handleThisYearPreset}
      />
      <RangeDatePicker
        startDate={startDate}
        handleStartDateChange={handleChangeStartDate}
        endDate={endDate}
        handleEndDateChange={handleChangeEndDate}
      />
    </Flex>
  );
};

export default TimePeriod;
