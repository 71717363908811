import { TimePeriodType } from 'interfaces/ReportInterfaces';

export const SET_TITLE = 'buildReport/setTitle';
export const ADD_OUTPUT = 'buildReport/addOutput';
export const REMOVE_OUTPUT = 'buildReport/removeOutput';
export const CHANGE_AUDIENCE = 'buildReport/changeAudience';
export const SET_TAGS = 'buildReport/setTags';
export const ADD_TAG = 'buildReport/addTag';
export const REMOVE_TAG = 'buildReport/removeTag';
export const CHANGE_PARAMETER = 'buildReport/changeParameter';
export const SET_TIME_PERIOD = 'buildReport/setTimePeriod';
export const SET_START_DATE = 'buildReport/setStartDate';
export const SET_END_DATE = 'buildReport/setEndDate';
export const RESET_FORM = 'buildReport/resetForm';
export const SET_USER_ID = 'buildReport/setUserId';
export const SET_SAVED = 'buildReport/setSaved';

export const AUDIENCE = 'buildReportFilter/Audience';
export const PARAMETER = 'buildReportFilter/Parameter';
export type ReportFilter = typeof AUDIENCE | typeof PARAMETER;

export interface ISetTitle {
  readonly type: typeof SET_TITLE;
  payload: {
    title: string;
  };
}

export interface IAddOutput {
  readonly type: typeof ADD_OUTPUT;
}

export interface IRemoveOutput {
  readonly type: typeof REMOVE_OUTPUT;
  payload: {
    index: number;
  };
}

export interface IChangeAudience {
  readonly type: typeof CHANGE_AUDIENCE;
  payload: {
    outputIndex: number;
    selectionIndex: number;
  };
}

export interface ISetTags {
  readonly type: typeof SET_TAGS;
  payload: {
    outputIndex: number;
    filter: ReportFilter;
    tags: string[];
  };
}

export interface IAddTag {
  readonly type: typeof ADD_TAG;
  payload: {
    outputIndex: number;
    filter: ReportFilter;
    tag: string;
  };
}

export interface IRemoveTag {
  readonly type: typeof REMOVE_TAG;
  payload: {
    outputIndex: number;
    filter: ReportFilter;
    tagIndex: number;
  };
}

export interface IChangeParameter {
  readonly type: typeof CHANGE_PARAMETER;
  payload: {
    outputIndex: number;
    selectionIndex: number;
  };
}

export interface ISetTimePeriod {
  readonly type: typeof SET_TIME_PERIOD;
  payload: {
    period: TimePeriodType;
  };
}

export interface ISetStartDate {
  readonly type: typeof SET_START_DATE;
  payload: {
    startDate: Date;
  };
}

export interface ISetEndDate {
  readonly type: typeof SET_END_DATE;
  payload: {
    endDate: Date;
  };
}

export interface IResetForm {
  readonly type: typeof RESET_FORM;
}

export interface ISetUserId {
  readonly type: typeof SET_USER_ID;
  payload: {
    userId: string;
  };
}

export interface ISetSaved {
  readonly type: typeof SET_SAVED;
  payload: {
    saved: boolean;
  };
}

export type BuildReportAction =
  | ISetTitle
  | IAddOutput
  | IRemoveOutput
  | IChangeAudience
  | ISetTags
  | IAddTag
  | IRemoveTag
  | IChangeParameter
  | ISetTimePeriod
  | ISetStartDate
  | ISetEndDate
  | IResetForm
  | ISetUserId
  | ISetSaved;

export const setTitle = (title: string): ISetTitle => ({
  type: SET_TITLE,
  payload: {
    title,
  },
});

export const addOutput = (): IAddOutput => ({
  type: ADD_OUTPUT,
});

export const removeOutput = (index: number): IRemoveOutput => ({
  type: REMOVE_OUTPUT,
  payload: {
    index,
  },
});

export const changeAudience = (
  outputIndex: number,
  selectionIndex: number
): IChangeAudience => ({
  type: CHANGE_AUDIENCE,
  payload: {
    outputIndex,
    selectionIndex,
  },
});

export const setTags = (
  outputIndex: number,
  filter: ReportFilter,
  tags: string[]
): ISetTags => ({
  type: SET_TAGS,
  payload: {
    filter,
    outputIndex,
    tags,
  },
});

export const addTag = (
  outputIndex: number,
  filter: ReportFilter,
  tag: string
): IAddTag => ({
  type: ADD_TAG,
  payload: {
    filter,
    outputIndex,
    tag,
  },
});

export const removeTag = (
  outputIndex: number,
  filter: ReportFilter,
  tagIndex: number
): IRemoveTag => ({
  type: REMOVE_TAG,
  payload: {
    filter,
    outputIndex,
    tagIndex,
  },
});

export const changeParameter = (
  outputIndex: number,
  selectionIndex: number
): IChangeParameter => ({
  type: CHANGE_PARAMETER,
  payload: {
    outputIndex,
    selectionIndex,
  },
});

export const setTimePeriod = (period: TimePeriodType): ISetTimePeriod => ({
  type: SET_TIME_PERIOD,
  payload: {
    period,
  },
});

export const setStartDate = (startDate: Date): ISetStartDate => ({
  type: SET_START_DATE,
  payload: {
    startDate,
  },
});

export const setEndDate = (endDate: Date): ISetEndDate => ({
  type: SET_END_DATE,
  payload: {
    endDate,
  },
});

export const resetForm = (): IResetForm => ({
  type: RESET_FORM,
});

export const setUserId = (userId: string): ISetUserId => ({
  type: SET_USER_ID,
  payload: {
    userId,
  },
});

export const setSaved = (saved: boolean): ISetSaved => ({
  type: SET_SAVED,
  payload: {
    saved,
  },
});
