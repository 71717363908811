import React from 'react';
import HierarchyView from './HierarchyView';
import useHierarchyViewModel from './HierarchyViewModel';

const Hierarchy: React.FC = () => {
  const viewModel = useHierarchyViewModel();

  return <HierarchyView {...viewModel} />;
};

export default Hierarchy;
