import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Box,
  Button,
  Divider,
  InputProps,
  Heading,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react';
import {
  DateObj,
  useDayzed,
  RenderProps,
  GetBackForwardPropsOptions,
  Calendar,
} from 'dayzed';
import * as dateFns from 'date-fns';

const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const DAY_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const DATE_FORMAT = 'MM/dd/yy';

interface SingleDatepickerBackButtonsProps {
  calendars: Calendar[];
  getBackProps: (data: GetBackForwardPropsOptions) => Record<string, unknown>;
}

const SingleDatepickerBackButtons = (
  props: SingleDatepickerBackButtonsProps
) => {
  const { calendars, getBackProps } = props;
  return (
    <>
      <Button
        {...getBackProps({
          calendars,
          offset: 12,
        })}
        marginLeft={2}
        marginRight={2}
        variant="ghost"
        size="xs"
      >
        {'<<'}
      </Button>
      <Button {...getBackProps({ calendars })} variant="ghost" size="xs">
        {'<'}
      </Button>
    </>
  );
};

interface SingleDatepickerForwardButtonsProps {
  calendars: Calendar[];
  getForwardProps: (
    data: GetBackForwardPropsOptions
  ) => Record<string, unknown>;
}

const SingleDatepickerForwardButtons = (
  props: SingleDatepickerForwardButtonsProps
) => {
  const { calendars, getForwardProps } = props;
  return (
    <>
      <Button
        {...getForwardProps({ calendars })}
        marginLeft={2}
        marginRight={2}
        variant="ghost"
        size="xs"
      >
        {'>'}
      </Button>
      <Button
        {...getForwardProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="xs"
      >
        {'>>'}
      </Button>
    </>
  );
};

const SingleDatepickerCalendar = (props: RenderProps) => {
  const { calendars, getDateProps, getBackProps, getForwardProps } = props;

  if (_.isEmpty(calendars)) {
    return null;
  }

  return (
    <Box>
      <HStack spacing={6} alignItems="baseline">
        {_.map(calendars, (calendar) => {
          return (
            <VStack w="100%" key={`${calendar.month}${calendar.year}`}>
              <HStack>
                <SingleDatepickerBackButtons
                  calendars={calendars}
                  getBackProps={getBackProps}
                />
                <Heading size="xs" textAlign="center">
                  {MONTH_NAMES[calendar.month]} {calendar.year}
                </Heading>
                <SingleDatepickerForwardButtons
                  calendars={calendars}
                  getForwardProps={getForwardProps}
                />
              </HStack>
              <Divider />
              <SimpleGrid columns={7} spacing={1} textAlign="center">
                {_.map(DAY_NAMES, (day) => (
                  <Box key={`${calendar.month}${calendar.year}${day}`}>
                    <Text fontSize="xs" fontWeight="semibold">
                      {day}
                    </Text>
                  </Box>
                ))}
                {_.map(calendar.weeks, (week, weekIndex) => {
                  return _.map(week, (dateObj: DateObj, index) => {
                    const { date, today, prevMonth, nextMonth, selected } =
                      dateObj;

                    const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;
                    const isDisabled = prevMonth || nextMonth;

                    const style = () => {
                      const obj: { [k: string]: unknown } = {
                        variant: 'outline',
                        borderColor: 'transparent',
                      };

                      if (today) {
                        obj.borderColor = 'blue.400';
                      }

                      if (selected) {
                        obj.bg = 'blue.200';
                      }

                      return obj;
                    };

                    return (
                      <Button
                        {...getDateProps({
                          dateObj,
                          disabled: isDisabled,
                        })}
                        key={key}
                        size="xs"
                        {...style()}
                      >
                        {date.getDate()}
                      </Button>
                    );
                  });
                })}
              </SimpleGrid>
            </VStack>
          );
        })}
      </HStack>
    </Box>
  );
};

export type SingleDatepickerProps = {
  value: Date | null;
  placeholder: string;
  disabled?: boolean;
  onChange: (date: Date) => void;
  maxDate?: Date;
  props?: InputProps;
};

// FIXME: This component currently stinks, I think it could be done better,
// in a more optimized and organized way
const SingleDatepicker: React.FC<SingleDatepickerProps> = ({
  value,
  placeholder,
  disabled,
  maxDate,
  onChange,
  props,
}) => {
  const ref = React.useRef<HTMLElement>(null);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const [proposedDate, setProposedDate] = useState<string>(
    value ? dateFns.format(value, DATE_FORMAT) : ''
  );
  const [popoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    if (dateFns.isValid(value) && value) {
      setProposedDate(dateFns.format(value, DATE_FORMAT));
    } else if (!value) {
      setProposedDate('');
    }
  }, [value]);

  useOutsideClick({
    ref,
    handler: () => setPopoverOpen(false),
  });

  const onChangePrime = useCallback(
    (date: Date) => {
      onChange(date);
    },
    [onChange]
  );

  const onDateSelected = (options: { selectable: boolean; date: Date }) => {
    const { selectable, date } = options;

    if (!selectable) {
      return;
    }

    if (!_.isNil(date)) {
      onChangePrime(date);
      setPopoverOpen(false);
    }
  };

  const dayzedData = useDayzed({
    showOutsideDays: true,
    onDateSelected,
    selected: value || undefined,
    maxDate: maxDate || new Date(),
  });

  return (
    <Popover
      placement="bottom-start"
      variant="responsive"
      isOpen={popoverOpen}
      onClose={() => setPopoverOpen(false)}
      initialFocusRef={initialFocusRef}
      isLazy
    >
      <PopoverTrigger>
        <Input
          placeholder={placeholder}
          bg="blue.50"
          variant="filled"
          borderRadius="full"
          textAlign="center"
          border="none"
          value={proposedDate}
          isDisabled={disabled}
          _focus={{ bg: 'blue.50' }}
          _hover={{ bg: 'blue.100' }}
          size="xs"
          w="6.57rem"
          h="2.25rem"
          paddingInline={4}
          paddingBlock={1}
          fontSize="14px"
          readOnly
          {...props}
          ref={initialFocusRef}
          onClick={() => setPopoverOpen(!popoverOpen)}
          onBlur={() => {
            const d = dateFns.parse(proposedDate, DATE_FORMAT, new Date());
            if (dateFns.isValid(d)) onChangePrime(d);
          }}
        />
      </PopoverTrigger>
      <PopoverContent ref={ref}>
        <PopoverBody>
          <SingleDatepickerCalendar {...dayzedData} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default React.memo(SingleDatepicker);
