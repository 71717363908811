import { useEffect, useState } from 'react';
import {
  AUDIENCE,
  PARAMETER,
  ReportFilter,
  setTags,
} from 'actions/buildReportActions';
import { useBuildReportContext } from 'contexts/buildReportContext';
import FilterFieldBusiness from './FilterFieldBusiness';

interface IFilterFieldViewModel {
  validAudienceTags: string[];
  validParameterTags: string[];
  loading: boolean;
}

const useFilterFieldViewModel: (
  type: ReportFilter,
  selection: string,
  outputIndex: number
) => IFilterFieldViewModel = (
  type: ReportFilter,
  selection: string,
  outputIndex: number
) => {
  const [validAudienceTags, setValidAudienceTags] = useState<string[]>([]);
  const [validParameterTags, setValidParameterTags] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { dispatch, state } = useBuildReportContext();

  // useEffect(() => {
  //   console.log('[VALID TAGS]', validTags);
  // }, [validTags]);

  useEffect(() => {
    setValidAudienceTags(
      state.currentReport.Outputs[outputIndex].AudienceDetail
    );
  }, [state.currentReport.Outputs[outputIndex].AudienceDetail]);

  useEffect(() => {
    setValidParameterTags(
      state.currentReport.Outputs[outputIndex].ParameterDetail
    );
  }, [state.currentReport.Outputs[outputIndex].ParameterDetail]);

  useEffect(() => {
    if (selection && selection !== '') {
      setLoading(true);
      if (type === AUDIENCE) {
        FilterFieldBusiness.getAudienceTags(selection).then((audienceTags) => {
          dispatch(setTags(outputIndex, AUDIENCE, audienceTags));
          setValidAudienceTags(
            state.currentReport.Outputs[outputIndex].AudienceDetail
          );
          setLoading(false);
        });
      } else if (type === PARAMETER) {
        FilterFieldBusiness.getParameterTags(selection).then(
          (parameterTags) => {
            dispatch(setTags(outputIndex, PARAMETER, parameterTags));
            setValidParameterTags(
              state.currentReport.Outputs[outputIndex].ParameterDetail
            );
            setLoading(false);
          }
        );
      }
    }
  }, [type, selection]);

  return {
    validAudienceTags,
    validParameterTags,
    loading,
  };
};

export default useFilterFieldViewModel;
