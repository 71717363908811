import { extendTheme, ThemeConfig, ThemeOverride } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import Button from './components/Button';
import Checkbox from './components/Checkbox';
import Heading from './components/Heading';
import Input from './components/Input';
import Select from './components/Select';
import Text from './components/Text';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const override: ThemeOverride = {
  fonts: {
    body: 'ATT Aleck Sans',
  },
  components: { Input, Button, Text, Heading, Select, Checkbox },
  styles: {
    global: (props) => ({
      body: {
        bg: mode('gray.300', 'gray.800')(props),
      },
    }),
  },
  colors: {
    gray: {
      100: '#EFF3F5',
      200: '#F7F7F7',
      300: '#D8E2E7',
      400: '#9DBBC7',
      500: '#CFD4D9',
      600: '#6c6c6c',
      900: '#252F39',
    },
    blue: {
      '50': '#E6F0F4',
      '100': '#F0F7FA',
      '200': '#8ADFFF',
      '300': '#449ED6',
      '400': '#009FDB',
      '500': '#0057B8',
    },
  },
  config,
};

const theme = extendTheme(override);

export default theme;
