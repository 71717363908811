import { resetForm, setSaved } from 'actions/buildReportActions';
import { useBuildReportContext } from 'contexts/buildReportContext';
import {
  closeDownloadModal,
  finishLoadingModal,
  startLoadingModal,
} from 'actions/modalActions';
import { useDownloadModalContext } from 'contexts/downloadModalContext';
import {
  REPORT_PRESETS,
  PREDEFINED_REPORT,
  SUMMARY,
  DETAIL,
  ADHOC_REPORT,
  IPDFTable,
  IPDFReport,
  ISavedReport,
  SAVED_REPORT,
} from 'interfaces/ReportInterfaces';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Reports from 'utils/ReportsUtils';
import { getSavedReportById } from 'services/AdHocReports';
import DownloadModalBusiness from './DownloadModalBusiness';

interface IDownloadModalViewModel {
  isOpen: boolean;
  isChecked: boolean;
  presetName: string;
  presetType: REPORT_PRESETS;
  customName: string;
  loading: boolean;
  setCustomName: Dispatch<SetStateAction<string>>;
  onClose: () => void;
  onCheckboxChange: () => void;
  onDetailReportClick: () => void;
  onSummaryReportClick: () => void;
}

declare global {
  interface Window {
    pdfData?: IPDFReport;
  }
}

const useDownloadModalViewModel: () => IDownloadModalViewModel = () => {
  const { state, dispatch } = useDownloadModalContext();
  const reportContext = useBuildReportContext();
  const { isOpen, presetName, presetType, loading, presetId } = state;
  const [customName, setCustomName] = useState(presetName);
  const [save, setSave] = useState(true);
  const {
    getCannedReportData,
    getAdhocDetailReport,
    getAdhocSummaryReport,
    saveAdhocReport,
    getSummarySavedReport,
    getDetailSavedReport,
  } = DownloadModalBusiness();

  const onCheckboxChange = useCallback(() => {
    setSave(!save);
  }, [save]);

  const onClose = useCallback(() => {
    setCustomName('');
    dispatch(closeDownloadModal());
    reportContext.dispatch(resetForm());
  }, []);

  useEffect(() => {
    setCustomName(presetName);
  }, [presetName]);

  const generateCSVReport = useCallback((adHocReportData: IPDFReport) => {
    const csvData = Reports.reportDataToCsvData(
      adHocReportData?.reportTableData ?? ([] as IPDFTable[])
    );

    Reports.generateCSV(adHocReportData.reportTitle ?? '', csvData);
  }, []);

  const openSummaryReport = useCallback((data?: IPDFReport) => {
    const newWindow = window.open('report-viewer', '_blank');
    if (newWindow) {
      newWindow.pdfData = data;
    }
  }, []);

  const getDownloadDetailAdhocReport = useCallback(
    async (report: ISavedReport) => {
      const adHocReportData = await getAdhocDetailReport(report);

      dispatch(finishLoadingModal());

      if (adHocReportData.success && adHocReportData.data?.reportTitle) {
        generateCSVReport(adHocReportData.data);
      } else {
        alert('Something went wrong!');
      }
    },
    []
  );
  const getDownloadSummaryAdhocReport = useCallback(
    async (report: ISavedReport) => {
      const adHocReportData = await getAdhocSummaryReport(report);
      dispatch(finishLoadingModal());

      if (adHocReportData.success) {
        if (adHocReportData.data) adHocReportData.data.showChart = false;
        openSummaryReport(adHocReportData.data);
      } else {
        alert('Something went wrong!');
      }
    },
    []
  );

  const downloadSavedReportSummary = useCallback(async () => {
    dispatch(startLoadingModal());
    const currentReport = await getSavedReportById(presetId ?? -1);
    if ((currentReport?.data?.Id ?? -1) >= 0) {
      const response = await getSummarySavedReport(currentReport.data);
      if (response.success) {
        openSummaryReport(response.data);
      } else {
        alert('Something went wrong!');
      }
    } else {
      alert('Fail to find the report!');
    }
    dispatch(finishLoadingModal());
  }, [presetId]);

  const downloadSavedReportDetail = useCallback(async () => {
    dispatch(startLoadingModal());
    const currentReport = await getSavedReportById(presetId ?? -1);
    if ((currentReport?.data?.Id ?? -1) >= 0) {
      const response = await getDetailSavedReport(currentReport.data);
      if (response.success && response.data?.reportTitle) {
        generateCSVReport(response.data);
      } else {
        alert('Something went wrong!');
      }
    } else {
      alert('Fail to find the report!');
    }
    dispatch(finishLoadingModal());
  }, [presetId]);

  const downloadAdhocSummaryReport = useCallback(async () => {
    try {
      if (presetType === ADHOC_REPORT) {
        const { currentReport } = reportContext.state;

        dispatch(startLoadingModal());
        if (save && !reportContext.state.isSaved) {
          console.log('save');
          await saveAdhocReport(currentReport);
          reportContext.dispatch(setSaved(true));
        }

        await getDownloadSummaryAdhocReport(currentReport);
      }
    } catch (error) {
      dispatch(finishLoadingModal());
      alert('Something went wrong!');
    }
  }, [presetType, reportContext.state]);

  const downloadAdhocDetailReport = useCallback(async () => {
    try {
      if (presetType === ADHOC_REPORT) {
        const { currentReport } = reportContext.state;

        dispatch(startLoadingModal());
        if (save && !reportContext.state.isSaved) {
          console.log('save');
          await saveAdhocReport(currentReport);
          reportContext.dispatch(setSaved(true));
        }

        await getDownloadDetailAdhocReport(currentReport);
      }
    } catch (error) {
      dispatch(finishLoadingModal());
      alert('Something went wrong!');
    }
  }, [presetType, reportContext.state]);

  const downloadCannedSummaryReport = useCallback(async () => {
    if (Reports.isReportPresets(presetType)) {
      dispatch(startLoadingModal());
      const cannedReportData = await getCannedReportData(
        presetType as PREDEFINED_REPORT,
        SUMMARY
      );
      dispatch(finishLoadingModal());

      if (cannedReportData.success) {
        if (cannedReportData.data)
          cannedReportData.data.showChart =
            cannedReportData.data.reportTableData.length > 0;

        const newWindow = window.open(
          `${window.location.origin}/report-viewer`,
          '_blank'
        );
        if (newWindow) {
          newWindow.pdfData = cannedReportData.data;
        }
      } else {
        alert('Something went wrong!');
      }
    }
  }, [presetType]);

  const downloadCannedDetailReport = useCallback(async () => {
    if (Reports.isReportPresets(presetType)) {
      dispatch(startLoadingModal());
      const cannedReportData = await getCannedReportData(
        presetType as PREDEFINED_REPORT,
        DETAIL
      );
      dispatch(finishLoadingModal());

      if (cannedReportData.success) {
        const csvData = Reports.reportDataToCsvData(
          cannedReportData.data?.reportTableData ?? ([] as IPDFTable[])
        );

        Reports.generateCSV(cannedReportData.data?.reportTitle ?? '', csvData);
      } else {
        alert('Something went wrong!');
      }
    }
  }, [presetType]);

  const onSummaryReportClick = useCallback(() => {
    switch (presetType) {
      case ADHOC_REPORT:
        downloadAdhocSummaryReport();
        break;
      case SAVED_REPORT:
        downloadSavedReportSummary();
        break;
      default:
        downloadCannedSummaryReport();
        break;
    }
  }, [presetType, downloadSavedReportSummary]);

  const onDetailReportClick = useCallback(() => {
    switch (presetType) {
      case ADHOC_REPORT:
        downloadAdhocDetailReport();
        break;
      case SAVED_REPORT:
        downloadSavedReportDetail();
        break;
      default:
        downloadCannedDetailReport();
        break;
    }
  }, [presetType, downloadSavedReportDetail]);

  const downloadModalViewModel: IDownloadModalViewModel = {
    isOpen,
    isChecked: save,
    presetName,
    presetType,
    customName,
    loading,
    setCustomName,
    onClose,
    onCheckboxChange,
    onDetailReportClick,
    onSummaryReportClick,
  };

  return downloadModalViewModel;
};

export default useDownloadModalViewModel;
