import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import FileInput from 'components/FileInput';
import Header from 'components/Header';
import { useTranslation } from 'react-i18next';
import HierarchyActionCard from './HierarchyActionCard';
import { IHierarchyViewModel } from './interfaces';

const HierarchyView: React.FC<IHierarchyViewModel> = ({
  error,
  loading,
  onDownloadFile,
  onSelectFile,
  onUploadFile,
}) => {
  const { t } = useTranslation('hierarchy');

  return (
    <Flex flex="1" bg="blue.100" flexDir="column">
      <Header title={t('title')} />
      <Flex w="100%" alignItems="center" justifyContent="center">
        <HierarchyActionCard
          title={t('uploadCardTitle')}
          type="UPLOAD_STYLE"
          marginRight={6}
        >
          <Text>{t('uploadCardDescription')}</Text>
          <FileInput
            accept=".xlsx"
            isDisabled={loading}
            onChange={onSelectFile}
          />
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            w="100%"
          >
            <Text mb={2} fontSize="14px" color="red.300">
              {error && !error.isDownload ? error.message : ''}
            </Text>
            <Button
              colorScheme="blue"
              disabled={loading}
              onClick={onUploadFile}
              w="100%"
            >
              {t('uploadButton')}
            </Button>
          </Flex>
        </HierarchyActionCard>
        <HierarchyActionCard
          title={t('downloadCardTitle')}
          type="DOWNLOAD_STYLE"
        >
          <Text>{t('downloadCardDescription')}</Text>
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            w="100%"
          >
            <Text mb={2} fontSize="14px" color="red.300">
              {error && error.isDownload ? error.message : ''}
            </Text>
            <Button
              colorScheme="blue"
              disabled={loading}
              onClick={onDownloadFile}
              w="100%"
            >
              {t('downloadButton')}
            </Button>
          </Flex>
        </HierarchyActionCard>
      </Flex>
    </Flex>
  );
};

export default HierarchyView;
