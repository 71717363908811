import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useUserContext } from 'contexts/userContext';
import { disconnectUser, loginUser } from 'actions/userActions';
import { IUserState } from 'reducers/userReducer';
import STORAGE from 'utils/storageKeys';

interface IUseAuth {
  isAuth: () => boolean;
  isAdmin: () => boolean;
  login: (userState: IUserState) => void;
  logout: () => void;
  loginCached: () => IUserState | boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const useAuth: () => IUseAuth = () => {
  const { state, dispatch } = useUserContext();
  const { user, auth } = state;

  const history = useHistory();

  const login = useCallback(
    (userState: IUserState) => {
      dispatch(loginUser(userState));

      localStorage.setItem(STORAGE.USER_DATA_KEY, JSON.stringify(userState));
    },
    [dispatch]
  );

  const loginCached = useCallback(() => {
    const userData = localStorage.getItem(STORAGE.USER_DATA_KEY);

    if (userData === null) return false;

    const userState: IUserState = JSON.parse(userData);

    return userState;
  }, [dispatch]);

  const logout = useCallback(() => {
    dispatch(disconnectUser());

    localStorage.removeItem(STORAGE.USER_DATA_KEY);
    history.replace('/');
  }, [dispatch, history]);

  const isAuth = useCallback(() => {
    const userState = loginCached();

    if (userState) {
      dispatch(loginUser(userState));
      return userState.user !== '';
    }

    return user !== '';
  }, [user, dispatch]);

  const isAdmin = useCallback(() => {
    if (isAuth()) return auth === 'admin';

    return false;
  }, [auth, dispatch]);

  return {
    isAuth,
    isAdmin,
    login,
    loginCached,
    logout,
  };
};
