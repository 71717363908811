import React, { useMemo } from 'react';
import { MdAddCircle } from 'react-icons/md';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Button, ButtonProps, Icon, Text, Tooltip } from '@chakra-ui/react';

type ITagButton = ButtonProps & {
  icon: 'add' | 'remove';
  tag: string;
  onAdd?: (tag: string) => void;
  onRemove?: () => void;
  isTooltipHidden?: boolean;
};

const TagButton: React.FC<ITagButton> = ({
  tag,
  icon,
  onAdd,
  onRemove,
  isTooltipHidden,
  ...props
}) => {
  const addCircleIcon = useMemo(
    () => <Icon as={MdAddCircle} color="gray.900" w="18px" h="18px" />,
    []
  );
  const removeCircleIcon = useMemo(
    () => <Icon as={AiFillCloseCircle} color="gray.900" w="18px" h="18px" />,
    []
  );

  return (
    <Button
      key={tag}
      rightIcon={icon === 'add' ? addCircleIcon : removeCircleIcon}
      variant="tag"
      textAlign="right"
      marginTop={2}
      border={0}
      color="black"
      bg="gray.300"
      _hover={{ bg: 'gray.300' }}
      maxW="14.1875rem"
      {...props}
      onClick={() =>
        icon === 'add' ? onAdd?.call({}, tag) : onRemove?.call({})
      }
    >
      <Tooltip label={tag} hidden={isTooltipHidden}>
        <Text isTruncated>{tag}</Text>
      </Tooltip>
    </Button>
  );
};

TagButton.defaultProps = {
  onAdd: undefined,
  onRemove: undefined,
  isTooltipHidden: undefined,
};

export default TagButton;
