import {
  initialState,
  IBuildReportState,
  buildReportReducer,
} from 'reducers/buildReportReducer';
import React, { createContext, useContext, useReducer } from 'react';
import { IBuildReportContext } from './interface';

const BuildReportContext = createContext<IBuildReportContext>({
  state: initialState(),
  dispatch: () => undefined,
});

interface IBuildReportProvider {
  state?: IBuildReportState;
}

const BuildReportProvider: React.FC<IBuildReportProvider> = ({
  children,
  state,
}) => {
  const [buildReportState, buildReportDispatch] = useReducer(
    buildReportReducer,
    {
      ...initialState(),
      ...state,
    }
  );

  return (
    <BuildReportContext.Provider
      value={{
        state: buildReportState,
        dispatch: buildReportDispatch,
      }}
    >
      {children}
    </BuildReportContext.Provider>
  );
};

BuildReportProvider.defaultProps = {
  state: undefined,
};

export const useBuildReportContext = (): IBuildReportContext =>
  useContext(BuildReportContext);

export default BuildReportProvider;
