import { useCallback, useState } from 'react';
import { ILoginFormViewModel } from './interface';
import useLoginFormBusiness from './LoginFormBusiness';

const useLoginFormViewModel: () => ILoginFormViewModel = () => {
  const [userId, setUserId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { doLogin } = useLoginFormBusiness();

  const onSubmit = useCallback(async () => {
    const response = await doLogin(userId, password);
    if (!response.success) {
      setError(response.errorMessage ?? '');
    }
  }, [userId, password]);

  const returnData: ILoginFormViewModel = {
    userId,
    setUserId,
    password,
    setPassword,
    onSubmit,
    error,
  };

  return returnData;
};

export default useLoginFormViewModel;
