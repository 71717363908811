import { Flex, Text, FlexProps } from '@chakra-ui/react';
import React, { memo } from 'react';

type ICard = FlexProps & {
  title: string;
  leftAddon?: React.ReactNode;
};

const Card: React.FC<ICard> = ({ title, children, leftAddon, ...props }) => {
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      border="1px"
      borderColor="blue.50"
      boxSizing="border-box"
      marginRight={4}
      marginBottom={5}
      paddingLeft={5}
      paddingRight="1.875rem"
      paddingTop={4}
      paddingBottom={7}
      {...props}
    >
      <Flex w="100%" justifyContent="space-between">
        <Text
          textAlign="left"
          fontSize="14px"
          fontWeight="700"
          marginBottom={4}
        >
          {title}
        </Text>
        {leftAddon}
      </Flex>
      {children}
    </Flex>
  );
};

Card.defaultProps = {
  leftAddon: undefined,
};

export default memo(Card);
