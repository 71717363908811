import { Flex, Text, Heading, Image } from '@chakra-ui/react';
import Footer from 'components/Footer';
import LoginForm from 'components/LoginForm';
import React from 'react';
import dtvLogo from 'assets/images/dtvStreamLogo.png';
import { useTranslation } from 'react-i18next';

const Login: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      backgroundColor="#F0F7FA"
    >
      <Flex
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        backgroundColor="#FFF"
        width="581px"
      >
        <Image src={dtvLogo} width="152px" height="152px" marginTop="5rem" />
        <Heading
          marginTop="3rem"
          fontSize="38px"
          fontWeight="700"
          lineHeight="50px"
          fontFamily="Jubilat"
          fontStyle="italic"
        >
          {t('headingAtt')}
        </Heading>
        <Heading
          marginBottom="3.3rem"
          fontSize="38px"
          fontWeight="400"
          lineHeight="42px"
          fontFamily="PF DIN Text Pro"
        >
          {t('headingReporting')}
        </Heading>

        <Text
          marginBottom={10}
          fontSize="22px"
          color="#0057B8"
          fontWeight="700"
          fontFamily="ATT Aleck Sans"
        >
          {t('signIn')}
        </Text>

        <LoginForm />
      </Flex>
      <Footer />
    </Flex>
  );
};

export default Login;
