interface IObject {
  [key: string]: any;
}

export function objectsEqual(o1: IObject, o2: IObject): boolean {
  return (
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p])
  );
}

export function arraysEqual(a1: IObject[], a2: IObject[]): boolean {
  return (
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))
  );
}

export function toBoolean(val: string | boolean): boolean {
  if (typeof val === 'string') {
    return val === 'true';
  }

  return val;
}
