import * as actions from 'actions/userActions';

export interface IUserState {
  token: string;
  refreshToken: string;
  user: string;
  auth: string;
}

export const initialState = (): IUserState => ({
  token: '',
  refreshToken: '',
  user: '',
  auth: '',
});

export const userReducer = (
  state: IUserState,
  action: actions.UserAction
): IUserState => {
  switch (action.type) {
    case actions.LOGIN_USER:
      return {
        ...state,
        user: action.payload.user,
        auth: action.payload.auth,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      };
    case actions.DISCONNECT_USER:
      return initialState();
    default:
      return { ...state };
  }
};
