import { useAuth } from 'hooks/useAuth';
import { MemoryHistory } from 'history';
import { useHistory } from 'react-router-dom';
import { DoLoginResponse, ILoginFormBusiness } from './interface';
import LoginFormNetwork from './LoginFormNetwork';

const useLoginFormBusiness = (
  historyProp?: MemoryHistory
): ILoginFormBusiness => {
  const history = useHistory();
  const { login } = useAuth();

  const doLogin = async (userId: string, password: string) => {
    const response = await LoginFormNetwork.handleLogin(userId, password);
    const doLoginResponse: DoLoginResponse = {
      success: response.success,
    };
    if (response.success) {
      login(response.payload);
      if (historyProp) {
        historyProp.replace('/home');
      } else {
        history.replace('/home');
      }
    } else {
      doLoginResponse.errorMessage = 'Wrong user/password combination';
    }

    return doLoginResponse;
  };

  const loginFormBusinessObj: ILoginFormBusiness = {
    doLogin,
  };

  return loginFormBusinessObj;
};

export default useLoginFormBusiness;
