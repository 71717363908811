import { useAuth } from 'hooks/useAuth';
import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

const UnauthRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const { isAuth } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (isAuth()) {
      history.replace('/home');
    }
  }, [history]);

  return (
    <Route exact {...props}>
      {children}
    </Route>
  );
};

export default UnauthRoute;
