import { IPresetItem } from 'interfaces/ReportInterfaces';
import HomeNetwork from './HomeNetwork';
import {
  IHomeBusiness,
  IPresetDeletedResponse,
  IPresetResponse,
} from './interfaces';

const getSavedPresets = async (
  user: string,
  t: (key: string) => string
): Promise<IPresetResponse> => {
  const response = await HomeNetwork.savedPresets(user);

  const presetResponse: IPresetResponse = {
    presets: [],
  };

  if (response.success) {
    presetResponse.presets = response.payload;
  } else {
    presetResponse.errorMessage = t('errorSavedPresets');
  }

  return presetResponse;
};

const deleteSavedPresets = async (
  savedReport: IPresetItem,
  t: (key: string) => string
): Promise<IPresetDeletedResponse> => {
  const response = await HomeNetwork.deletePreset(savedReport);
  if (response.success) {
    return {};
  }
  return { errorMessage: t('errorSavedPresets') };
};

const HomeBusiness: IHomeBusiness = { getSavedPresets, deleteSavedPresets };

export default HomeBusiness;
