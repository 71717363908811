import commonENUS from './common.json';
import homeENUS from './home.json';
import hierarchyENUS from './hierarchy.json';
import loginENUS from './login.json';
import navbarENUS from './navbar.json';
import footerENUS from './footer.json';
import buildReportENUS from './buildReport.json';
import downloadModalENUS from './downloadModal.json';
import pdfHeaderENUS from './pdfHeader.json';
import pdfReportDescENUS from './pdfReportDesc.json';

export default {
  common: commonENUS,
  home: homeENUS,
  hierarchy: hierarchyENUS,
  login: loginENUS,
  navbar: navbarENUS,
  footer: footerENUS,
  buildReport: buildReportENUS,
  downloadModal: downloadModalENUS,
  pdfHeader: pdfHeaderENUS,
  pdfReportDesc: pdfReportDescENUS,
};
