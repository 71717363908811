import React from 'react';

import { BrowserRouter } from 'react-router-dom';

import UserProvider from 'contexts/userContext';
import DownloadModalProvider from 'contexts/downloadModalContext';
import BuildReportProvider from 'contexts/buildReportContext';
import SavedReportProvider from 'contexts/savedReportsContext';
import 'locale';
import Routes from './routes';

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <UserProvider>
        <SavedReportProvider state={[]}>
          <BuildReportProvider>
            <DownloadModalProvider>
              <Routes />
            </DownloadModalProvider>
          </BuildReportProvider>
        </SavedReportProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
