import React from 'react';
import { Flex, Text, TextProps } from '@chakra-ui/layout';
import Logo from 'assets/images/dtvStreamLogo.png';
import { useTranslation } from 'react-i18next';

interface IPDFHeader {
  generatedAt: string;
  timeFrameFrom: string;
  timeFrameTo: string;
}

const textDefault: TextProps = {
  lineHeight: '14px',
  color: '#000',
  fontFamily: 'ATT Aleck Sans',
};

const textTitle: TextProps = {
  ...textDefault,
  fontSize: '12px',
  fontWeight: '700',
  fontStyle: 'italic',
  lineHeight: '14.4px',
};

const textSubtitle: TextProps = {
  ...textTitle,
  fontWeight: '400',
  fontStyle: 'normal',
};

const textDetail: TextProps = {
  ...textDefault,
  fontSize: '10px',
  fontWeight: '700',
  textAlign: 'right',
};

const textDetailValue: TextProps = {
  ...textDetail,
  fontWeight: 'normal',
};

const PDFHeader: React.FC<IPDFHeader> = ({
  generatedAt,
  timeFrameFrom,
  timeFrameTo,
}) => {
  const { t } = useTranslation(['common', 'pdfHeader']);

  return (
    <Flex height="80px" backgroundColor="#FFF" alignItems="center">
      <Flex flexGrow={3}>
        <img style={{ width: '56px', height: '56px' }} alt="" src={Logo} />
        <Flex marginLeft="17px" alignSelf="center" direction="column">
          <Text {...textTitle}>{t('appName')}</Text>
          <Text {...textSubtitle}>{t('headingReporting')}</Text>
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" flexGrow={1}>
        <Flex direction="column">
          <Text {...textDetail}>{t('generated', { ns: 'pdfHeader' })}</Text>
          <Text {...textDetailValue}>{generatedAt}</Text>
        </Flex>
        <Flex direction="column">
          <Text {...textDetail}>{t('timeFrame', { ns: 'pdfHeader' })}</Text>
          <Text {...textDetailValue}>
            {timeFrameFrom} - {timeFrameTo}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default PDFHeader;
