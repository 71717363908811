import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

interface ITagPopoverViewModel {
  currentTags: string[];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  clearSearch: () => void;
}

const useTagPopoverViewModel: (tags: string[]) => ITagPopoverViewModel = (
  tags: string[]
) => {
  const [currentTags, setCurrentTags] = useState(tags);
  const [search, setSearch] = useState('');

  const filterTags = useCallback(() => {
    if (search !== '') {
      const filtered = tags.filter((value) =>
        value.toLowerCase().includes(search.toLowerCase())
      );

      setCurrentTags(filtered);
    } else {
      setCurrentTags(tags);
    }
  }, [tags, search, currentTags, setCurrentTags]);

  const debounceFilterTags = useCallback(
    debounce(() => {
      filterTags();
    }, 500),
    [filterTags]
  );

  useEffect(() => {
    setCurrentTags(tags);
  }, [tags]);

  const clearSearch = useCallback(() => setSearch(''), [setSearch]);

  useEffect(() => {
    debounceFilterTags();
  }, [search, debounce]);

  return {
    currentTags,
    search,
    setSearch,
    clearSearch,
  };
};

export default useTagPopoverViewModel;
