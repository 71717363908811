import React, { useMemo } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import Card from 'components/Card';
import Header from 'components/Header';
import Input from 'components/Input';
import Footer from 'components/Footer';
import { useTranslation } from 'react-i18next';
import OutputList from './OutputList';
import TimePeriod from './TimePeriod';
import { IBuildReportViewModel } from './interfaces';

const BuildReportView: React.FC<IBuildReportViewModel> = ({
  title,
  handleTitleChange,
  handleAddOutput,
  handleResetForm,
  handleSubmit,
  isSubmitEnabled,
}) => {
  const addIcon = useMemo(() => <AddIcon w="10px" h="10px" />, []);
  const { t } = useTranslation('buildReport');

  return (
    <Flex flex="1" bg="blue.100" flexDir="column" alignItems="center">
      <Header title={t('title')} />
      <Flex bg="white" flexDir="column" w="94%" padding={5}>
        <Flex>
          <Card border="none" minW="44%" title="REPORT TITLE">
            <Input
              value={title}
              setValue={handleTitleChange}
              fontStyle="italic"
              placeholder={t('inputPlaceholder')}
            />
          </Card>

          <Flex
            flex="1"
            flexDir="column"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <Button
              marginBottom={4}
              size="xs"
              variant="outline"
              borderColor="gray.600"
              textColor="gray.600"
              _hover={{ bg: 'gray.100' }}
              onClick={handleResetForm}
            >
              {t('restFormButton')}
            </Button>
          </Flex>
        </Flex>
        <Flex flexDir="column">
          <OutputList />
          <Button
            w="9.125rem"
            mt="0.625rem"
            mb="1.875rem"
            variant="tag"
            rightIcon={addIcon}
            onClick={handleAddOutput}
          >
            {t('addOutputButton')}
          </Button>
          <Flex justifyContent="space-between">
            <Card
              alignItems="flex-start"
              mb={0}
              w="60%"
              title={t('timePeriod')}
            >
              <TimePeriod />
            </Card>
            <Button
              disabled={!isSubmitEnabled}
              alignSelf="flex-end"
              size="md"
              w="14.875rem"
              h="4rem"
              onClick={handleSubmit}
            >
              {t('saveButton')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default BuildReportView;
