import React from 'react';
import { IPresetItem, REPORT_PRESETS } from 'interfaces/ReportInterfaces';
import { Text } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';
import PresetItem from './PresetItem';

export interface IPresetList {
  onDelete: (savedReport: IPresetItem) => void;
  onOpen: (
    preset: string,
    type: REPORT_PRESETS,
    id?: number | undefined
  ) => void;
  presets: IPresetItem[];
  errorMessage?: string;
}

const PresetList: React.FC<IPresetList> = ({
  presets,
  errorMessage,
  onDelete,
  onOpen,
}) => {
  const { t } = useTranslation('common');

  if (presets.length > 0) {
    return (
      <>
        {presets.map((preset) => (
          <PresetItem
            onOpen={onOpen}
            onDelete={onDelete}
            type={preset.type}
            key={preset.id}
            name={preset.name}
            id={preset.id}
          />
        ))}
      </>
    );
  }
  return (
    <>
      {errorMessage !== '' ? (
        <Text alignSelf="center" fontSize="14px" color="red.300">
          {errorMessage}
        </Text>
      ) : (
        <Text
          alignSelf="center"
          fontWeight="200"
          fontStyle="italic"
          fontSize="16px"
          color="black"
        >
          {t('emptyList')}
        </Text>
      )}
    </>
  );
};

export default PresetList;
