/* eslint-disable react/no-array-index-key */
import React, { forwardRef, useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Bar } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import {
  IPDFTable,
  IPDFReport,
  ADHOC_REPORT,
} from 'interfaces/ReportInterfaces';
import TBDReportGraphs from 'components/TBDReportGraphs';
import PDFFooter from './PDFFooter';
import PDFHeader from './PDFHeader';
import PDFReportDescription from './PDFReportDescription';
import PDFTable from './PDFTable';

const TableList: React.FC<{ tables: IPDFTable[] }> = ({ tables }) => (
  <>
    {tables.map((table, index) => (
      <PDFTable
        key={`${table.tableData.headers.toString()}_${
          table.tableDescription.title
        }_${index}`}
        tableData={table.tableData}
        tableDescription={table.tableDescription}
      />
    ))}
  </>
);

const PDFReport = forwardRef<any, IPDFReport>((props, ref) => {
  const {
    generatedAt,
    timeFrameFrom,
    timeFrameTo,
    reportTitle,
    reportAboutText,
    reportIncludedItems,
    reportTableData,
    reportSubtitle,
    showChart,
    reportType,
  } = props;

  const [chartData, setChartData] = useState<
    ChartData<'bar', number[], unknown>
  >({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });

  useEffect(() => {
    if (showChart) {
      const labels: string[] = [];
      const data: number[] = [];
      const backgroundColor: string[] = [];

      // Special logic for TBD Report, as the graphs are pretty different than
      // the others. Yep, this is disgusting, I know.
      if (!reportTitle.includes('IS&D')) {
        reportTableData.forEach((tableData) => {
          const {
            tableDescription: { group, totalValue },
          } = tableData;

          const chartBackgroundColor = group.includes('ATTR')
            ? '#449ED6'
            : '#0057B8';

          labels.push(group);
          data.push(parseInt(totalValue, 10));
          backgroundColor.push(chartBackgroundColor);
        });
      }

      setChartData({
        labels,
        datasets: [
          {
            type: 'bar',
            data,
            backgroundColor,
          },
        ],
      });
    }
  }, []);

  const getPageMargins = () => {
    return `@page { margin: 0.25in 0.25in 0.25in 0.25in }`;
  };

  return (
    <Flex flex="1" ref={ref} justifyContent="center">
      <style>{getPageMargins()}</style>
      <Flex
        flex="1"
        backgroundColor="#FFF"
        direction="column"
        padding="12px"
        maxWidth="700px"
      >
        <PDFHeader
          generatedAt={generatedAt}
          timeFrameFrom={timeFrameFrom}
          timeFrameTo={timeFrameTo}
        />
        <Flex
          flex="1"
          backgroundColor="#FFF"
          marginBottom="16px"
          justifyContent="space-between"
        >
          <PDFReportDescription
            reportTitle={reportTitle}
            reportIncludedItems={reportIncludedItems}
            reportAboutText={reportAboutText}
            reportSubtitle={reportSubtitle}
            showAbout={reportType !== ADHOC_REPORT}
            maxW={reportTitle.includes('IS&D') ? '100%' : '197px'}
          />
          {showChart && !reportTitle.includes('IS&D') && (
            <Flex
              data-testid="report-chart"
              flexDirection="column"
              flex="1"
              border="solid #000 2px"
              padding="20px"
              maxWidth="309px"
            >
              <Text
                fontSize="20px"
                fontFamily="ATT Aleck Sans"
                fontWeight="bold"
                width="200px"
                lineHeight="20px"
                marginBottom="10px"
              >
                {reportIncludedItems[0].substr(3)}
              </Text>
              <Text
                fontSize="10px"
                fontFamily="ATT Aleck Sans"
                fontWeight="bold"
                marginBottom="23px"
                lineHeight="10px"
              >
                {`${timeFrameFrom} - ${timeFrameTo}`}
              </Text>
              {!reportTitle.includes('IS&D') && (
                <Flex>
                  <Bar
                    width="289px"
                    height="470px"
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={chartData}
                  />
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
        {reportTitle.includes('IS&D') ? (
          <TBDReportGraphs reportTableData={reportTableData} />
        ) : (
          <TableList tables={reportTableData} />
        )}
        <PDFFooter />
      </Flex>
    </Flex>
  );
});

export default PDFReport;
