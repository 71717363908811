import {
  Flex,
  Button,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import React, { useCallback, useMemo, useState, useRef } from 'react';
import TagButton from 'components/TagButton';
import Input from 'components/Input';
import { AiFillCloseCircle } from 'react-icons/ai';
import { debounce } from 'lodash';
import useTagPopoverViewModel from './viewModel';

interface IAddTagPopover {
  tags: string[];
  text: string;
  onSelect: (tag: string) => void;
}

const AddTagPopover: React.FC<IAddTagPopover> = ({ text, tags, onSelect }) => {
  const { currentTags, search, setSearch, clearSearch } =
    useTagPopoverViewModel(tags);

  const [isScrolling, setIsScrolling] = useState(false);
  const popoverRef = useRef<HTMLElement>(null);

  const addIcon = useMemo(() => <AddIcon w="10px" h="10px" color="blue" />, []);
  const removeButton = useMemo(
    () => (
      <Flex
        alignItems="center"
        justifyContent="center"
        onClick={clearSearch}
        _hover={{ cursor: 'pointer' }}
      >
        <Icon as={AiFillCloseCircle} color="gray.900" w="18px" h="18px" />
      </Flex>
    ),
    []
  );
  const placeholderText = useMemo(() => {
    switch (text) {
      case 'DOS':
        return `Search ${text}es`;
      default:
        return `Search ${text}s`;
    }
  }, [text]);

  const handleSelect = (tag: string) => {
    onSelect(tag);
    popoverRef.current?.focus();
  };

  const debounceScroll = useCallback(() => {
    setIsScrolling(true);
    debounce(() => {
      setIsScrolling(false);
    }, 500)();
  }, []);

  return (
    <Popover closeOnBlur placement="bottom-start" isLazy>
      <PopoverTrigger>
        <Button
          autoFocus={false}
          marginLeft={6}
          variant="tagAudience"
          rightIcon={addIcon}
        >
          {`ADD ${text.toUpperCase()}`}
        </Button>
      </PopoverTrigger>

      <PopoverContent
        ref={popoverRef}
        padding={2}
        maxW="17rem"
        maxH="14rem"
        bg="gray.100"
      >
        <PopoverArrow bg="gray.100" />
        <Input
          value={search}
          setValue={setSearch}
          placeholder={placeholderText}
          variant="filled"
          borderRadius="full"
          border={0}
          padding={4}
          fontSize="14px"
          _hover={{ bg: 'white' }}
          marginBottom={2}
          h="2.25rem"
          rightAddon={removeButton}
          rightAddonHidden={search === ''}
        />

        <PopoverBody
          overflowY={currentTags.length > 5 ? 'scroll' : 'hidden'}
          css={{
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#7f7f7f',
              borderRadius: '24px',
              width: '8px',
            },
          }}
          onScroll={debounceScroll}
        >
          {currentTags.map(
            (tag) =>
              tag &&
              tag !== '' && (
                <TagButton
                  autoFocus={false}
                  key={tag}
                  tag={tag}
                  icon="add"
                  onAdd={handleSelect}
                  isTooltipHidden={isScrolling}
                />
              )
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AddTagPopover;
