import React, { useMemo } from 'react';
import Input from 'components/Input';
import LinkText from 'components/LinkText';

import { Button, Flex, Icon, useDisclosure } from '@chakra-ui/react';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import useLoginFormViewModel from './LoginFormViewModel';

const LoginForm: React.FC = () => {
  const { userId, password, error, setPassword, setUserId, onSubmit } =
    useLoginFormViewModel();

  const { isOpen, onToggle } = useDisclosure();
  const { t } = useTranslation('login');

  const viewPasswordButton = useMemo(
    () => (
      <Flex
        alignItems="center"
        justifyContent="center"
        onClick={onToggle}
        _hover={{ cursor: 'pointer' }}
        marginRight={6}
      >
        <Icon
          as={isOpen ? AiOutlineEyeInvisible : AiOutlineEye}
          w="24px"
          h="24px"
        />
      </Flex>
    ),
    [isOpen]
  );

  return (
    <Flex flexDir="column" justifyContent="center" alignItems="center">
      {error}
      <Input
        variant="filled"
        placeholder={t('userPlaceholder')}
        marginBottom={5}
        size="lg"
        value={userId}
        setValue={setUserId}
        fontFamily="ATT Aleck Sans"
      />
      <Input
        variant="filled"
        placeholder={t('passwordPlaceholder')}
        marginBottom={2}
        size="lg"
        value={password}
        setValue={setPassword}
        type={isOpen ? 'text' : 'password'}
        rightAddon={viewPasswordButton}
        fontFamily="ATT Aleck Sans"
      />
      <Flex justifyContent="flex-end" width="100%">
        <LinkText text={t('forgotPassword')} textDecoration="none" />
      </Flex>

      <Button marginTop={9} size="md" colorScheme="blue" onClick={onSubmit}>
        {t('signIn')}
      </Button>

      <LinkText
        marginTop={6}
        marginBottom={40}
        lineHeight="26px"
        fontSize="14px"
        text={t('needHelp')}
        fontFamily="ATT Aleck Sans"
        textDecoration="none"
      />
    </Flex>
  );
};

export default LoginForm;
