import DownloadModal from 'components/DownloadModal';
import { initialState, downloadModalReducer } from 'reducers/modalReducer';
import React, { createContext, useContext, useReducer } from 'react';
import { IDownloadModalContext } from './interface';

const DownloadModalContext = createContext<IDownloadModalContext>({
  state: initialState(),
  dispatch: () => undefined,
});

const DownloadModalProvider: React.FC = ({ children }) => {
  const [userState, userDispatch] = useReducer(
    downloadModalReducer,
    initialState()
  );

  return (
    <DownloadModalContext.Provider
      value={{
        state: userState,
        dispatch: userDispatch,
      }}
    >
      {children}
      <DownloadModal />
    </DownloadModalContext.Provider>
  );
};

export const useDownloadModalContext = (): IDownloadModalContext =>
  useContext(DownloadModalContext);

export default DownloadModalProvider;
