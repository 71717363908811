import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const AdminRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const { isAdmin } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!isAdmin()) {
      history.replace('/');
    }
  }, [history]);

  return (
    <Route exact {...props}>
      {children}
    </Route>
  );
};

export default AdminRoute;
