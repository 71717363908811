import { Flex, Text, Image, Button } from '@chakra-ui/react';
import React from 'react';

import dtvLogo from 'assets/images/dtvStreamLogo.png';
import { useUserContext } from 'contexts/userContext';
import LinkText from 'components/LinkText';
import { GearIcon, UserIcon } from 'components/Icons';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import useNavbarViewModel from './NavbarViewModel';

const Navbar: React.FC = () => {
  const { state } = useUserContext();
  const { logout } = useAuth();
  const { user } = state;
  const { onCustomReport, onLoadReport, onLoadHierarchy } =
    useNavbarViewModel();
  const { t } = useTranslation(['common', 'navbar']);

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      bg="black"
      h="4.875rem"
      minH="4.875rem"
    >
      <Flex alignItems="center">
        <Image marginLeft={3} w="56px" h="56px" src={dtvLogo} />
        <Flex marginLeft={4} flexDir="column">
          <Text
            color="white"
            fontFamily="Jubilat"
            fontWeight="700"
            fontSize="18px"
            as="i"
            lineHeight="17px"
          >
            {t('appName')}
          </Text>
          <Text color="white" fontSize="14px" lineHeight="17px">
            {t('headingReporting')}
          </Text>
        </Flex>
      </Flex>

      <Flex alignItems="center">
        <LinkText
          color="white"
          marginRight="3rem"
          decoration="none"
          fontSize="14px"
          lineHeight="28px"
          _hover={{ textDecoration: 'underline' }}
          onClick={onLoadReport}
        >
          {t('loadReport', { ns: 'navbar' })}
        </LinkText>
        <LinkText
          color="white"
          marginRight="3.5175rem"
          decoration="none"
          fontSize="14px"
          lineHeight="28px"
          _hover={{ textDecoration: 'underline' }}
          onClick={onCustomReport}
        >
          {t('buildCustomReport', { ns: 'navbar' })}
        </LinkText>

        <GearIcon
          onClick={onLoadHierarchy}
          _hover={{ cursor: 'pointer' }}
          marginRight="1.7325rem"
        />
        <UserIcon marginRight={2} />

        <LinkText
          color="white"
          marginRight={6}
          decoration="none"
          fontSize="14px"
          lineHeight="28px"
        >
          {user}
        </LinkText>
        <Button
          size="xs"
          minW="5.3125rem"
          variant="outline"
          borderColor="gray.400"
          textColor="gray.400"
          marginRight={6}
          bgColor="black"
          _hover={{ bg: 'gray.600' }}
          onClick={logout}
        >
          {t('logout', { ns: 'navbar' })}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Navbar;
