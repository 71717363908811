import React, { ReactNode } from 'react';

interface IConditionalRenderer {
  condition: boolean;
  positiveComponent: ReactNode;
  negativeComponent: ReactNode;
}

const ConditionalRenderer: React.FC<IConditionalRenderer> = ({
  condition,
  positiveComponent,
  negativeComponent,
}) => {
  return <>{condition ? positiveComponent : negativeComponent}</>;
};

export default ConditionalRenderer;
