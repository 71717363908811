import { openDownloadModal } from 'actions/modalActions';
import { useDownloadModalContext } from 'contexts/downloadModalContext';
import { useSavedReportContext } from 'contexts/savedReportsContext';
import { useUserContext } from 'contexts/userContext';
import {
  DEVICE_ACTIVATION,
  DISCREET_SESSION,
  INACTIVE_USERS,
  IPresetItem,
  REPORT_PRESETS,
  SSC_VIEW_COUNT,
  TBD,
  USAGE_PER_TAB,
} from 'interfaces/ReportInterfaces';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IS_ATTR } from 'utils/ReportsUtils';
import HomeBusiness from './HomeBusiness';
import { IHomeViewModel } from './interfaces';

const presetList: IPresetItem[] = [
  { id: 1, name: 'Discreet sessions over last 7 days', type: DISCREET_SESSION },
  { id: 2, name: 'SSC sessions over last 30 days', type: SSC_VIEW_COUNT },
  { id: 3, name: 'Inactive users over last 7 days', type: INACTIVE_USERS },
  { id: 4, name: 'Usage per tab over last 30 days', type: USAGE_PER_TAB },
  { id: 5, name: 'Activations during last 7 days', type: DEVICE_ACTIVATION },
];

const useHomeViewModel: () => IHomeViewModel = () => {
  const presets = useMemo(() => {
    const tbdItem: IPresetItem = {
      id: 6,
      name: 'IS&D Device & Usage Report',
      type: TBD,
    };

    if (!IS_ATTR && !presetList.find((item) => item.type === TBD)) {
      presetList.push(tbdItem);
    }

    return presetList;
  }, []);
  const [savedError, setSavedError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();
  const { dispatch } = useDownloadModalContext();
  const { getSavedPresets, deleteSavedPresets } = HomeBusiness;
  const { state } = useUserContext();
  const savedReportContext = useSavedReportContext();
  const { t } = useTranslation('home');

  const onBuildCustomReport = useCallback(() => {
    history.push('/build');
  }, []);

  const loadSavedPresets = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getSavedPresets(state.user, t);
      if (response.errorMessage) {
        setSavedError(response.errorMessage);
      } else {
        savedReportContext.setState(response.presets);
        setSavedError('');
      }
    } catch (error) {
      setSavedError(t('errorSavedPresets'));
    } finally {
      setLoading(false);
    }
  }, [state.user]);

  useEffect(() => {
    loadSavedPresets();
  }, [state.user]);

  const removeSavedPreset = useCallback(
    async (savedReport: IPresetItem) => {
      setLoading(true);
      const response = await deleteSavedPresets(savedReport, t);
      if (response.errorMessage) {
        setSavedError(response.errorMessage);
      } else {
        const newPresets = savedReportContext.state.filter(
          (item) => item.id !== savedReport.id
        );
        savedReportContext.setState(newPresets);
        setSavedError('');
        setLoading(false);
      }
    },
    [savedReportContext.state]
  );

  const openDModal = useCallback(
    (preset: string, type: REPORT_PRESETS, id?: number) => {
      dispatch(
        openDownloadModal({
          presetName: preset,
          presetType: type,
          presetId: id ?? -1,
        })
      );
    },
    []
  );

  const homeViewModel: IHomeViewModel = {
    presets,
    savedPresets: savedReportContext.state,
    savedError,
    loading,
    onBuildCustomReport,
    removeSavedPreset,
    openDModal,
  };

  return homeViewModel;
};

export default useHomeViewModel;
