import React, { memo } from 'react';
import AddTagPopover from 'components/AddTagPopover';
import TagButton from 'components/TagButton';
import CustomSelect from 'components/Select';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import { AUDIENCE, ReportFilter } from 'actions/buildReportActions';
import useFilterFieldViewModel from './FilterFieldViewModel';

interface IFilterField {
  type: ReportFilter;
  options: readonly string[];
  tagLabel: string;
  tagList: string[];
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onSelectTag: (tag: string) => void;
  onRemoveTag: (index: number) => void;
  outputIndex: number;
}

const FilterField: React.FC<IFilterField> = ({
  type,
  options,
  tagLabel,
  tagList,
  handleChange,
  onSelectTag,
  onRemoveTag,
  outputIndex,
}) => {
  const { validAudienceTags, validParameterTags, loading } =
    useFilterFieldViewModel(type, tagLabel, outputIndex);

  const showAddPopover =
    type === AUDIENCE
      ? validAudienceTags.length > 0
      : validParameterTags.length > 0;

  return (
    <Flex w="100%" alignItems="center">
      <Text fontSize="14px" marginRight={10}>
        {type === AUDIENCE ? 'Audience:' : 'Parameter:'}
      </Text>

      <CustomSelect
        placeholder={`SELECT ${type === AUDIENCE ? 'AUDIENCE' : 'PARAMETER'}`}
        options={options}
        value={options.indexOf(tagLabel)}
        onChange={handleChange}
      />

      {tagLabel !== '' && showAddPopover && (
        <AddTagPopover
          tags={type === AUDIENCE ? validAudienceTags : validParameterTags}
          onSelect={onSelectTag}
          text={tagLabel.substring(0, tagLabel.indexOf(' ')) || tagLabel}
        />
      )}
      {loading && <Spinner ml={4} />}
      <Flex flexDir="row" flexWrap="wrap">
        {tagList.map((tag, idx) => (
          <TagButton
            key={tag}
            marginTop={0}
            marginLeft={2}
            mb={2}
            icon="remove"
            tag={tag}
            onRemove={() => onRemoveTag(idx)}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default memo(FilterField);
