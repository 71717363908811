import { Thead } from '@chakra-ui/react';
import React from 'react';
import PDFRow from './PDFTableRow';

interface IPDFTableHeader {
  titles: string[];
}

const PDFTableHeader: React.FC<IPDFTableHeader> = ({ titles }) => {
  return (
    <Thead>
      <PDFRow rows={titles} header />
    </Thead>
  );
};

export default PDFTableHeader;
