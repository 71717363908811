import { Flex } from '@chakra-ui/react';
import Navbar from 'components/Navbar';
import React from 'react';

const Screen: React.FC = ({ children }) => {
  return (
    <Flex h="100vh" w="100vw" flexDir="column">
      <Navbar />
      {children}
    </Flex>
  );
};

export default Screen;
