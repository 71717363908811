import { SettingsIcon } from '@chakra-ui/icons';
import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const UserIcon: React.FC<IconProps> = ({ ...props }) => (
  <Icon color="white" w={6} h={6} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.667 14.75c-3.17 0-5.917 2.767-5.917 6.339V22a.75.75 0 0 1-1.5 0v-.911c0-4.327 3.347-7.839 7.417-7.839h2.666c4.178 0 7.417 3.628 7.417 7.839V22a.75.75 0 0 1-1.5 0v-.911c0-3.485-2.666-6.339-5.917-6.339h-2.666ZM12 10.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm0 1.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
      fill="#fff"
    />
  </Icon>
);

export const GearIcon: React.FC<IconProps> = ({ ...props }) => (
  <SettingsIcon color="white" w={5} h={5} {...props} />
);
