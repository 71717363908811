import { Flex, Heading } from '@chakra-ui/react';
import React, { memo } from 'react';

interface IHeader {
  title: string;
}

const Header: React.FC<IHeader> = ({ title }) => {
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      w="100%"
      h="11rem"
      minH="11rem"
    >
      <Heading color="blue.500" letterSpacing="0.002em" fontSize="42px">
        {title}
      </Heading>
    </Flex>
  );
};

export default memo(Header);
