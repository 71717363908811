import {
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Flex,
  Checkbox,
  Box,
  Button,
  Spinner,
} from '@chakra-ui/react';
import ConditionalRenderer from 'components/ConditionalRenderer';
import Input from 'components/Input';
import { useBuildReportContext } from 'contexts/buildReportContext';
import { SAVED_REPORT } from 'interfaces/ReportInterfaces';
import Reports from 'utils/ReportsUtils';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useDownloadModalViewModel from './DownloadModalViewModel';

const DownloadModal: React.FC = () => {
  const {
    presetName,
    presetType,
    isOpen,
    isChecked,
    customName,
    loading,
    setCustomName,
    onClose,
    onCheckboxChange,
    onDetailReportClick,
    onSummaryReportClick,
  } = useDownloadModalViewModel();
  const { state } = useBuildReportContext();
  const { isSaved } = state;
  const { t } = useTranslation('downloadModal');
  const ref = useRef<HTMLButtonElement>(null);

  const adhocFields = useMemo(
    () => (
      <Flex flexDir="column">
        <Text fontSize="16px" lineHeight="20.94px" fontWeight="bold">
          {t('title')}
        </Text>
        <Input
          mt={3}
          fontSize="20px"
          value={customName}
          setValue={setCustomName}
          placeholder="Untitled Report 1"
          _placeholder={{ fontStyle: 'italic' }}
        />
        {isSaved ? (
          <Box mt={6} fontSize="14px">
            {t('alreadySaved')}
          </Box>
        ) : (
          <Checkbox
            size="lg"
            marginTop={6}
            isChecked={isChecked}
            onChange={onCheckboxChange}
          >
            <Box fontSize="14px">{t('savedMyReport')}</Box>
          </Checkbox>
        )}
      </Flex>
    ),
    [customName, isChecked, isSaved]
  );

  const cannedFields = useMemo(
    () => (
      <Flex justifyContent="center">
        <Text
          alignSelf="center"
          textAlign="center"
          w="80%"
          color="black"
          fontSize="22px"
          lineHeight="28.8px"
        >
          {presetName}
        </Text>
      </Flex>
    ),
    [presetName]
  );

  const modalBody = useMemo(
    () => (
      <>
        <ConditionalRenderer
          condition={
            Reports.isReportPresets(presetType) || presetType === SAVED_REPORT
          }
          positiveComponent={cannedFields}
          negativeComponent={adhocFields}
        />
        <Text
          mt={10}
          mb={4}
          fontSize="16px"
          lineHeight="20.94px"
          fontWeight="bold"
        >
          {t('downloadReport')}
        </Text>

        <Flex justifyContent="space-around">
          <Button ref={ref} size="sm" onClick={onSummaryReportClick}>
            {t('summaryPDF')}
          </Button>
          <Button bg="blue.500" size="sm" onClick={onDetailReportClick}>
            {t('detailedExcel')}
          </Button>
        </Flex>
      </>
    ),
    [presetType, cannedFields, adhocFields]
  );

  const loader = useMemo(
    () => (
      <Flex flex="1" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    ),
    []
  );

  return (
    <Modal
      initialFocusRef={ref}
      closeOnOverlayClick
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        paddingInline={4}
        bg="blue.50"
        borderRadius="none"
        boxShadow="0px 2px 24px rgba(0, 0, 0, 0.25)"
      >
        <ModalCloseButton onClick={onClose} />
        <ModalHeader textAlign="center">
          {Reports.isReportPresets(presetType) || presetType === SAVED_REPORT
            ? `${t('downloadReport')}:`
            : t('modalSaveButton')}
        </ModalHeader>
        <ModalBody>
          <ConditionalRenderer
            condition={loading}
            positiveComponent={loader}
            negativeComponent={modalBody}
          />
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default DownloadModal;
