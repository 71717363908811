import { IPresetItem } from 'interfaces/ReportInterfaces';
import React, { useState } from 'react';

import { createContext, useContextSelector } from 'use-context-selector';
import { ISavedReportContext } from './interface';

const SavedReportContext = createContext<ISavedReportContext>({
  state: [],
  setState: () => undefined,
});

interface ISavedReportProvider {
  state: IPresetItem[];
}

const SavedReportProvider: React.FC<ISavedReportProvider> = ({
  children,
  state,
}) => {
  const [savedReport, setSavedReport] = useState<IPresetItem[]>([
    ...(state ?? []),
  ]);

  return (
    <SavedReportContext.Provider
      value={{
        setState: setSavedReport,
        state: savedReport,
      }}
    >
      {children}
    </SavedReportContext.Provider>
  );
};

export const useSavedReportContext = (): ISavedReportContext =>
  useContextSelector(SavedReportContext, (context) => context);

export default SavedReportProvider;
