import React from 'react';
import { Flex, Text, TextProps } from '@chakra-ui/layout';
import { IPDFTableDescription } from 'interfaces/ReportInterfaces';

const textDefault: TextProps = {
  letterSpacing: '-4%',
  fontWeight: '700',
  color: '#000',
  textTransform: 'uppercase',
  fontFamily: 'ATT Aleck Sans',
};

const textTimeFrame: TextProps = {
  ...textDefault,
  fontSize: '8px',
  textAlign: 'right',
  lineHeight: '9.6px',
};

const textReportTitle: TextProps = {
  ...textDefault,
  fontSize: '12px',
  lineHeight: '14.4px',
};

const textReportDataGroup: TextProps = {
  ...textDefault,
  fontSize: '16px',
  lineHeight: '14.4px',
  marginTop: '3px',
  marginBottom: '5px',
};

const textReportDateRange: TextProps = {
  ...textDefault,
  fontSize: '8px',
  lineHeight: '14.4px',
};
const textReportTotal: TextProps = {
  ...textDefault,
  fontSize: '25px',
  lineHeight: '20px',
  textAlign: 'right',
  paddingBottom: '15px',
};

const PDFTableDescription: React.FC<IPDFTableDescription> = ({
  title,
  group,
  dateFrom,
  dateTo,
  totalTitle,
  dateRange,
  totalValue,
}) => (
  <Flex margin="15px">
    <Flex flexGrow={3} direction="column">
      <Text {...textReportTitle}>{title}</Text>
      <Text {...textReportDataGroup}>{group}</Text>
      <Text {...textReportDateRange}>
        {dateFrom} - {dateTo}
      </Text>
    </Flex>
    <Flex flexGrow={1} alignItems="center" justifyContent="space-between">
      <Flex direction="column" alignItems="flex-end">
        <Text {...textTimeFrame}>{totalTitle}</Text>
        <Text {...textTimeFrame}>{dateRange}</Text>
      </Flex>
      <Text {...textReportTotal}>{totalValue}</Text>
    </Flex>
  </Flex>
);

export default PDFTableDescription;
