/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeEvent, useState } from 'react';
import useHierarchyBusiness from './HierarchyBusiness';
import { IHierarchyError, IHierarchyViewModel } from './interfaces';

const useHierarchyViewModel: () => IHierarchyViewModel = () => {
  const { uploadFile, downloadFile } = useHierarchyBusiness();

  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<IHierarchyError | undefined>(undefined);

  const onSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const onUploadFile = async () => {
    setLoading(true);

    if (!file) {
      setError({
        isDownload: false,
        message: 'Please select a file to upload.',
      });
      setLoading(false);
      return;
    }

    const response = await uploadFile(file);
    const errorMessage = response.success
      ? ''
      : 'Error uploading file. Please try again.';

    setError({ isDownload: false, message: errorMessage });
    setLoading(false);
  };

  const onDownloadFile = async () => {
    setLoading(true);

    const response = await downloadFile();
    const errorMessage = response
      ? ''
      : 'Error downloading file. Please try again.';

    setError({ isDownload: true, message: errorMessage });
    setLoading(false);
  };

  return {
    loading,
    error,
    onSelectFile,
    onUploadFile,
    onDownloadFile,
  };
};

export default useHierarchyViewModel;
