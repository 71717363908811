/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Tr,
  Th,
  Td,
  TableColumnHeaderProps,
  TableCellProps,
} from '@chakra-ui/react';

interface IPDFRow {
  rows: string[];
  header?: boolean;
}

interface IPDFRowCell {
  row: string;
}

const thStyleProps: TableColumnHeaderProps = {
  fontSize: '8px',
  fontWeight: '700',
  letterSpacing: '2%',
  lineHeight: '16px',
  color: 'black',
  fontFamily: 'ATT Aleck Sans',
};

const tdStyleProps: TableCellProps = {
  fontSize: '8px',
  fontWeight: '400',
  letterSpacing: '-2%',
  lineHeight: '16px',
  color: 'black',
  textAlign: 'left',
  fontFamily: 'ATT Aleck Sans',
};

const PDFRowCell: React.FC<IPDFRowCell> = ({ row }) => {
  return (
    <Td {...tdStyleProps} padding={1}>
      {row ?? '(NULL)'}
    </Td>
  );
};

const PDFRowCellHeader: React.FC<IPDFRowCell> = ({ row }) => {
  return (
    <Th {...thStyleProps} padding={1}>
      {row ?? '(NULL)'}
    </Th>
  );
};

const PDFRow: React.FC<IPDFRow> = ({ rows, header }) => {
  return (
    <Tr>
      {rows.map((row, index) =>
        header ? (
          <PDFRowCellHeader row={row} key={`${row}_${index}`} />
        ) : (
          <PDFRowCell row={row} key={`${row}_${index}`} />
        )
      )}
    </Tr>
  );
};

PDFRow.defaultProps = {
  header: undefined,
};

export default PDFRow;
