import {
  getAudienceDetailItems,
  getParameterDetailItems,
} from 'services/AdHocReports';
import _ from 'lodash';

interface IFilterFieldBusiness {
  getAudienceTags: (audience: string) => Promise<string[]>;
  getParameterTags: (parameter: string) => Promise<string[]>;
}

const getAudienceTags = async (audience: string): Promise<string[]> => {
  const string = _.camelCase(audience);

  const audienceDetailItems = await getAudienceDetailItems(string);

  return audienceDetailItems.data.map((item) => item.AudienceDetail);
};

const getParameterTags = async (parameter: string): Promise<string[]> => {
  const string = _.camelCase(parameter);

  const parameterDetailItems = await getParameterDetailItems(string);

  return parameterDetailItems.data.map((item) => item.ParameterDetail);
};

const FilterFieldBusiness: IFilterFieldBusiness = {
  getAudienceTags,
  getParameterTags,
};

export default FilterFieldBusiness;
