import React, { memo, ReactNode } from 'react';
import { Flex, Text, TextProps } from '@chakra-ui/react';

type ILinkText = TextProps & {
  text?: string;
  addon?: ReactNode;
};

const LinkText: React.FC<ILinkText> = ({
  text,
  children,
  onClick,
  addon,
  ...props
}) => {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Text
        decoration="underline"
        fontSize="18px"
        css={{ '-webkit-user-select': 'none' }}
        onClick={onClick}
        {...props}
        // eslint-disable-next-line no-underscore-dangle
        _hover={{ ...props._hover, cursor: 'pointer' }}
      >
        {text || children}
      </Text>
      {addon}
    </Flex>
  );
};

LinkText.defaultProps = {
  text: undefined,
  addon: undefined,
};

export default memo(LinkText);
