import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Flex, Button } from '@chakra-ui/react';
import PDFReport from 'components/ReportComponents/PDFReport';
import { useTranslation } from 'react-i18next';

const ReportViewer: React.FC = () => {
  const { t, i18n } = useTranslation('common');
  const componentRef = useRef(null);
  const { pdfData }: any = window ?? {};
  console.log(pdfData);

  const handlePrint = useReactToPrint({
    pageStyle: `
      { 
        size: 8.5in 11in 
      }
      @media print {
        table {
          margin-bottom: 1rem;
        }
      }
    `,
    content: () => componentRef.current,
    documentTitle: pdfData?.reportTitle ?? 'Report',
  });

  return (
    <Flex flex="1" flexDirection="column" alignItems="center">
      <Button margin="10px" onClick={handlePrint}>
        {t('downloadReport')}
      </Button>
      <PDFReport {...pdfData} ref={componentRef} />
      <Button margin="10px" onClick={handlePrint}>
        {t('downloadReport')}
      </Button>
    </Flex>
  );
};

export default ReportViewer;
