const Select = {
  parts: ['field', 'icon'],
  baseStyle: {
    field: {
      letterSpacing: '0.02em',
    },
  },
  sizes: {
    md: {
      field: {
        fontSize: '0.875rem',
      },
    },
  },
};

export default Select;
