import React from 'react';
import BuildReportView from './BuildReportView';
import useBuildReportViewModel from './BuildReportViewModel';

const BuildReport: React.FC = () => {
  const viewModel = useBuildReportViewModel();

  return <BuildReportView {...viewModel} />;
};

export default BuildReport;
