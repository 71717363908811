import axios from 'axios';

const api = axios.create({
  baseURL: 'https://attr-analytics-api.azurewebsites.net/',
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
  },
});

export default api;
