import React, { memo } from 'react';
import { Select, SelectProps } from '@chakra-ui/react';

type ICustomSelect = SelectProps & {
  placeholder: string;
  options: string[] | readonly string[];
};

const CustomSelect: React.FC<ICustomSelect> = ({
  placeholder,
  options,
  ...props
}) => {
  return (
    <Select
      maxW="11.8125rem"
      maxH="2.25rem"
      variant="filled"
      bg="gray.200"
      {...props}
    >
      <option hidden value="">
        {placeholder}
      </option>
      {(options as readonly string[]).map((opt: string, idx) =>
        opt !== '' ? (
          <option key={opt} value={idx}>
            {opt}
          </option>
        ) : null
      )}
    </Select>
  );
};

export default memo(CustomSelect);
