import React from 'react';
import { Flex, FlexProps, Text, TextProps } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';

type IPDFReportDescription = FlexProps & {
  reportTitle: string;
  reportAboutText: string;
  reportSubtitle: string;
  reportIncludedItems: string[];
  showAbout: boolean;
};

const textDefault: TextProps = {
  lineHeight: '14px',
  fontSize: '10px',
  fontWeight: '300',
  color: '#000',
  fontFamily: 'ATT Aleck Sans',
};

const textBold: TextProps = {
  ...textDefault,
  fontWeight: '700',
  fontSize: '10px',
  lineHeight: '14px',
  textTransform: 'uppercase',
  fontFamily: 'ATT Aleck Sans',
};

const textTitle: TextProps = {
  ...textBold,
  lineHeight: '24px',
  fontSize: '20px',
};

const textSubtitle: TextProps = {
  ...textBold,
  fontSize: '8px',
  lineHeight: '12px',
};

const textAbout: TextProps = {
  ...textBold,
  fontSize: '10px',
  lineHeight: '14px',
  marginBottom: '8px',
};

const textItem: TextProps = {
  ...textDefault,
  fontSize: '10px',
  lineHeight: '16.8px',
};

const PDFReportIncludedReportItem: React.FC<{ id: number; value: string }> = ({
  id,
  value,
}) => <Text {...textItem} key={`${id}_${value}`}>{`${value}`}</Text>;

const PDFReportIncludedReportList: React.FC<{ list: string[] }> = ({
  list,
}) => (
  <>
    {list.map((item, index) => (
      <PDFReportIncludedReportItem
        id={index + 1}
        value={item}
        key={`${item}_${index + 1}`}
      />
    ))}
  </>
);

const PDFReportDescription: React.FC<IPDFReportDescription> = ({
  reportTitle,
  reportAboutText,
  reportIncludedItems,
  reportSubtitle,
  showAbout,
  maxWidth,
  maxW,
}) => {
  const { t } = useTranslation('pdfReportDesc');

  return (
    <Flex flex="1" direction="column" maxW={maxWidth || maxW}>
      <Flex direction="column" marginBottom="20px">
        <Text {...textBold} marginBottom="2px">
          {t('summaryReport')}
        </Text>
        <Text {...textTitle}>{reportTitle}</Text>
        <Text {...textSubtitle}>{reportSubtitle}</Text>
      </Flex>
      {showAbout && (
        <Flex direction="column">
          <Text {...textAbout}>{t('aboutThisReport')}</Text>
          <Text {...textDefault} marginBottom="8px" flexWrap="wrap">
            {reportAboutText}
          </Text>
          <Text {...textAbout}>{t('includeInReport')}</Text>
          <PDFReportIncludedReportList list={reportIncludedItems} />
        </Flex>
      )}
    </Flex>
  );
};

export default PDFReportDescription;
