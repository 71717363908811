import { useBuildReportContext } from 'contexts/buildReportContext';
import { useDownloadModalContext } from 'contexts/downloadModalContext';
import { useUserContext } from 'contexts/userContext';
import { useCallback, useMemo } from 'react';
import { IBuildReportViewModel } from './interfaces';
import BuildReportBusiness from './BuildReportBusiness';

const useBuildReportViewModel: () => IBuildReportViewModel = () => {
  const { state, dispatch } = useBuildReportContext();
  const modalContext = useDownloadModalContext();
  const userState = useUserContext().state;
  const { user } = userState;
  const { currentReport } = state;
  const { Title, StartDate, EndDate, TimePeriod, Outputs } = currentReport;
  const isSubmitEnabled = useMemo(
    () =>
      BuildReportBusiness.validateReport(
        TimePeriod,
        StartDate,
        EndDate,
        Title,
        Outputs
      ),
    [TimePeriod, StartDate, EndDate, Title, Outputs]
  );

  const handleTitleChange = useCallback((value: string) => {
    BuildReportBusiness.setReportTitle(dispatch, value);
  }, []);

  const handleAddOutput = useCallback(() => {
    BuildReportBusiness.addReportOutput(dispatch);
  }, []);

  const handleRemoveOutput = useCallback((index: number) => {
    BuildReportBusiness.removeReportOutput(dispatch, index);
  }, []);

  const handleAddAudienceTag = useCallback((index: number, tag: string) => {
    BuildReportBusiness.addReportAudienceTag(dispatch, index, tag);
  }, []);

  const handleAddParameterTag = useCallback((index: number, tag: string) => {
    BuildReportBusiness.addReportParameterTag(dispatch, index, tag);
  }, []);

  const handleRemoveAudienceTag = useCallback(
    (index: number, tagIndex: number) => {
      BuildReportBusiness.removeReportAudienceTag(dispatch, index, tagIndex);
    },
    []
  );

  const handleRemoveParameterTag = useCallback(
    (index: number, tagIndex: number) => {
      BuildReportBusiness.removeReportParameterTag(dispatch, index, tagIndex);
    },
    []
  );

  const handleChangeAudience = useCallback(
    (index: number, audienceIndex: number) => {
      BuildReportBusiness.changeReportAudience(dispatch, index, audienceIndex);
    },
    []
  );

  const handleChangeParameter = useCallback(
    (index: number, parameterIndex: number) => {
      BuildReportBusiness.changeReportParameter(
        dispatch,
        index,
        parameterIndex
      );
    },
    []
  );

  const handleChangeStartDate = useCallback((date: Date) => {
    BuildReportBusiness.setReportStartDate(dispatch, date);
  }, []);

  const handleChangeEndDate = useCallback((date: Date) => {
    BuildReportBusiness.setReportEndDate(dispatch, date);
  }, []);

  const handleLastWeek = useCallback(() => {
    BuildReportBusiness.setLast7DaysTimePeriod(dispatch);
  }, []);

  const handleLastMonthPreset = useCallback(() => {
    BuildReportBusiness.setLast4WeeksTimePeriod(dispatch);
  }, []);

  const handleMonthToDatePreset = useCallback(() => {
    BuildReportBusiness.setMonthToDateTimePeriod(dispatch);
  }, []);

  const handleThisYearPreset = useCallback(() => {
    BuildReportBusiness.setThisYearTimePeriod(dispatch);
  }, []);

  const handleResetForm = useCallback(() => {
    BuildReportBusiness.resetReport(dispatch);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (isSubmitEnabled) {
      BuildReportBusiness.openBuildReportModal(
        user,
        Title,
        dispatch,
        modalContext.dispatch
      );
    }
  }, [user, Title, isSubmitEnabled]);

  return {
    title: Title,
    startDate: StartDate,
    endDate: EndDate,
    timePeriod: TimePeriod,
    isSubmitEnabled,
    handleTitleChange,
    handleAddOutput,
    handleRemoveOutput,
    handleAddAudienceTag,
    handleAddParameterTag,
    handleRemoveAudienceTag,
    handleRemoveParameterTag,
    handleChangeAudience,
    handleChangeParameter,
    handleChangeStartDate,
    handleChangeEndDate,
    handleResetForm,
    handleSubmit,
    handleLastWeek,
    handleLastMonthPreset,
    handleMonthToDatePreset,
    handleThisYearPreset,
  };
};

export default useBuildReportViewModel;
