import { Flex, Text, Image } from '@chakra-ui/react';
import React from 'react';

import globeBlue from 'assets/images/attGlobeBlue.png';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation('footer');

  return (
    <Flex
      w="100%"
      h="100%"
      marginTop={12}
      marginBottom={6}
      alignItems="flex-end"
      justifyContent="space-between"
    >
      <Flex flexDir="column">
        <Text fontSize="12px" lineHeight="20px" marginLeft={6}>
          {t('directv')}
        </Text>
        <Text fontSize="12px" lineHeight="20px" marginLeft={6}>
          {t('attAndGlobo')}
        </Text>
      </Flex>

      <Image w="55px" h="55px" src={globeBlue} marginRight={8} />
    </Flex>
  );
};

export default Footer;
