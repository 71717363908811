import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import {
  PREDEFINED_REPORT,
  cannedReports,
  IPDFTable,
  ISavedReport,
  ISavedReportModel,
  IOutputModel,
} from 'interfaces/ReportInterfaces';
import _ from 'lodash';
import { toBoolean } from './ComparisonFunctions';

export interface ICsvItem {
  [key: string]: string;
}
export interface ICsvData {
  [key: string]: ICsvItem[];
}

export const IS_ATTR = toBoolean(process.env.REACT_APP_IS_ATTR ?? 'false');

const reportDataToCsvData = (reportData: IPDFTable[]): ICsvData => {
  const csvData: ICsvData = {};
  reportData.forEach(({ tableData, tableDescription }) => {
    const { title } = tableDescription;
    const { headers, rows } = tableData;
    csvData[title] = [];

    rows.forEach((row, rIndex) => {
      csvData[title][rIndex] = {};
      headers.forEach((header, hIndex) => {
        csvData[title][rIndex][`${header}`] =
          row[hIndex] === null ? '--' : row[hIndex];
      });
    });
  });

  return csvData;
};

const generateCSV = (filename: string, csvData: ICsvData): void => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  let Sheets: XLSX.WorkSheet = {};
  const SheetNames: string[] = [];
  Object.keys(csvData).forEach((csvKey) => {
    const ws = XLSX.utils.json_to_sheet(csvData[csvKey]);
    Sheets = {
      ...Sheets,
      [csvKey]: ws,
    };
    SheetNames.push(csvKey);
  });
  const wb = { Sheets, SheetNames };
  const excelBuffer = XLSX.write(
    { ...wb },
    { bookType: 'xlsx', type: 'array', cellStyles: true }
  );
  const data = new Blob([excelBuffer], { type: fileType });
  const date = new Date();
  const dateStr = format(date, 'MM_dd_yy');
  FileSaver.saveAs(data, `${filename}_${dateStr}${fileExtension}`);
};

const isReportPresets = (x: any): x is PREDEFINED_REPORT =>
  cannedReports.includes(x);

const savedReportToModel = (report: ISavedReport): ISavedReportModel => {
  const startDate = report.StartDate
    ? report.StartDate.toISOString().substr(0, 10)
    : null;
  const endDate = report.EndDate
    ? report.EndDate.toISOString().substr(0, 10)
    : null;

  const newOutputs = report.Outputs.map<IOutputModel>((output) => {
    const newOutput: IOutputModel = {
      Audience: _.camelCase(output.Audience),
      Parameter: _.camelCase(output.Parameter),
      AudienceDetail:
        (output.SelectedAudienceDetail?.length ?? 0) > 0
          ? output.SelectedAudienceDetail?.join(';')
          : null,
      ParameterDetail:
        (output.SelectedParameterDetail?.length ?? 0) > 0
          ? output.SelectedParameterDetail?.join(';')
          : null,
    };

    return newOutput;
  });

  return {
    Id: undefined,
    UserId: report.UserId,
    Title: report.Title,
    StartDate: startDate,
    EndDate: endDate,
    TimePeriod: report.TimePeriod,
    Outputs: newOutputs,
  };
};

const Reports = {
  generateCSV,
  isReportPresets,
  reportDataToCsvData,
  savedReportToModel,
};

export default Reports;
