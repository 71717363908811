import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import BuildReport from 'pages/BuildReport';
import Screen from 'pages/Screen';
import Home from 'pages/Home';
import ReportViewer from 'pages/ReportViewer';
import Hierarchy from 'pages/Hierarchy';
import AuthRoute from './AuthRoute';
import AdminRoute from './AdminRoute';
import UnauthRoute from './UnauthRoute';

const Routes: React.FC = () => {
  return (
    <Switch>
      <UnauthRoute exact path="/">
        <Login />
      </UnauthRoute>
      <AuthRoute exact path="/home">
        <Screen>
          <Home />
        </Screen>
      </AuthRoute>
      <AuthRoute exact path="/build">
        <Screen>
          <BuildReport />
        </Screen>
      </AuthRoute>
      <AuthRoute exact path="/hierarchy">
        <Screen>
          <Hierarchy />
        </Screen>
      </AuthRoute>
      <AuthRoute exact path="/report-viewer">
        <ReportViewer />
      </AuthRoute>
      <AdminRoute exact path="/admin">
        <div>Admin</div>
      </AdminRoute>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Routes;
