import React, { memo, ReactNode } from 'react';
import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';

type setValueType =
  | React.Dispatch<React.SetStateAction<string>>
  | ((value: string) => void);

type IInput = InputProps & {
  value: string;
  setValue: setValueType;
  rightAddon?: ReactNode;
  rightAddonHidden?: boolean;
};

const MemoizedInput: React.FC<IInput> = ({
  value,
  setValue,
  rightAddon,
  rightAddonHidden,
  ...props
}) => {
  return rightAddon ? (
    <InputGroup>
      <Input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        {...props}
      />
      <InputRightElement h="88%" hidden={rightAddonHidden ?? true}>
        {rightAddon}
      </InputRightElement>
    </InputGroup>
  ) : (
    <Input
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      {...props}
    />
  );
};

MemoizedInput.defaultProps = {
  rightAddon: undefined,
  rightAddonHidden: undefined,
};

export default memo(MemoizedInput);
