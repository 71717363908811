import * as actions from 'actions/buildReportActions';
import {
  Audiences,
  ISavedReport,
  Parameters,
} from 'interfaces/ReportInterfaces';

export interface IBuildReportState {
  isSaved: boolean;
  currentReport: ISavedReport;
}

export const initialState = (): IBuildReportState => ({
  isSaved: false,
  currentReport: {
    UserId: '',
    Title: '',
    TimePeriod: null,
    StartDate: null,
    EndDate: null,
    Outputs: [
      {
        Audience: '',
        AudienceDetail: [],
        SelectedAudienceDetail: [],
        Parameter: '',
        ParameterDetail: [],
        SelectedParameterDetail: [],
      },
    ],
  },
});

export const buildReportReducer = (
  state: IBuildReportState,
  action: actions.BuildReportAction
): IBuildReportState => {
  switch (action.type) {
    case actions.SET_TITLE:
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          Title: action.payload.title,
        },
      };
    case actions.ADD_OUTPUT: {
      const newOutputs = [...state.currentReport.Outputs];

      newOutputs.push({
        Audience: '',
        AudienceDetail: [],
        SelectedAudienceDetail: [],
        Parameter: '',
        ParameterDetail: [],
        SelectedParameterDetail: [],
      });

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          Outputs: newOutputs,
        },
      };
    }
    case actions.REMOVE_OUTPUT: {
      const newOutputs = [...state.currentReport.Outputs];

      newOutputs.splice(action.payload.index, 1);

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          Outputs: newOutputs,
        },
      };
    }
    case actions.CHANGE_AUDIENCE: {
      const newOutputs = [...state.currentReport.Outputs];

      newOutputs[action.payload.outputIndex].Audience =
        Audiences[action.payload.selectionIndex];
      newOutputs[action.payload.outputIndex].AudienceDetail = [];
      newOutputs[action.payload.outputIndex].SelectedAudienceDetail = [];

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          Outputs: newOutputs,
        },
      };
    }
    case actions.SET_TAGS: {
      const newOutputs = [...state.currentReport.Outputs];

      if (action.payload.filter === actions.AUDIENCE) {
        newOutputs[action.payload.outputIndex].AudienceDetail = [
          ...action.payload.tags,
        ];
      } else if (action.payload.filter === actions.PARAMETER) {
        newOutputs[action.payload.outputIndex].ParameterDetail = [
          ...action.payload.tags,
        ];
      }

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          Outputs: newOutputs,
        },
      };
    }
    case actions.ADD_TAG: {
      const newOutputs = [...state.currentReport.Outputs];
      const newTag = action.payload.tag;

      if (
        action.payload.filter === actions.AUDIENCE &&
        !newOutputs[action.payload.outputIndex].SelectedAudienceDetail.includes(
          newTag
        )
      ) {
        newOutputs[action.payload.outputIndex].SelectedAudienceDetail.push(
          newTag
        );
        const audienceResult = newOutputs[
          action.payload.outputIndex
        ].AudienceDetail.filter(
          (detail) =>
            !newOutputs[
              action.payload.outputIndex
            ].SelectedAudienceDetail.includes(detail)
        );
        newOutputs[action.payload.outputIndex].AudienceDetail = [
          ...audienceResult,
        ];
      } else if (
        action.payload.filter === actions.PARAMETER &&
        !newOutputs[action.payload.outputIndex].SelectedAudienceDetail.includes(
          newTag
        )
      ) {
        newOutputs[action.payload.outputIndex].SelectedParameterDetail.push(
          newTag
        );
        const parameterResult = newOutputs[
          action.payload.outputIndex
        ].ParameterDetail.filter(
          (detail) =>
            !newOutputs[
              action.payload.outputIndex
            ].SelectedParameterDetail.includes(detail)
        );
        newOutputs[action.payload.outputIndex].ParameterDetail = [
          ...parameterResult,
        ];
      }

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          Outputs: newOutputs,
        },
      };
    }
    case actions.REMOVE_TAG: {
      const newOutputs = [...state.currentReport.Outputs];

      if (action.payload.filter === actions.AUDIENCE) {
        const deletedElement = newOutputs[
          action.payload.outputIndex
        ].SelectedAudienceDetail.splice(action.payload.tagIndex, 1);
        newOutputs[action.payload.outputIndex].AudienceDetail.unshift(
          deletedElement[0]
        );
      } else {
        const deletedElement = newOutputs[
          action.payload.outputIndex
        ].SelectedParameterDetail.splice(action.payload.tagIndex, 1);
        newOutputs[action.payload.outputIndex].ParameterDetail.unshift(
          deletedElement[0]
        );
      }

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          Outputs: newOutputs,
        },
      };
    }
    case actions.CHANGE_PARAMETER: {
      const newOutputs = [...state.currentReport.Outputs];

      newOutputs[action.payload.outputIndex].Parameter =
        Parameters[action.payload.selectionIndex];
      newOutputs[action.payload.outputIndex].ParameterDetail = [];
      newOutputs[action.payload.outputIndex].SelectedParameterDetail = [];

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          Outputs: newOutputs,
        },
      };
    }
    case actions.SET_TIME_PERIOD:
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          TimePeriod: action.payload.period,
          StartDate: null,
          EndDate: null,
        },
      };
    case actions.SET_START_DATE: {
      const start = action.payload.startDate.getTime();
      const end = state.currentReport.EndDate?.getTime();

      let newEnd: Date | null = state.currentReport.EndDate;

      if (end && start > end) {
        newEnd = null;
      }

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          TimePeriod: null,
          StartDate: action.payload.startDate,
          EndDate: newEnd,
        },
      };
    }
    case actions.SET_END_DATE: {
      const start = state.currentReport.StartDate?.getTime();
      const end = action.payload.endDate.getTime();

      let newEnd: Date | null = action.payload.endDate;

      if (start && start > end) {
        newEnd = null;
      }

      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          TimePeriod: null,
          EndDate: newEnd,
        },
      };
    }
    case actions.RESET_FORM:
      return {
        ...initialState(),
      };
    case actions.SET_USER_ID:
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          UserId: action.payload.userId,
        },
      };
    case actions.SET_SAVED:
      console.log('SET_SAVED', action.payload.saved);
      return {
        ...state,
        isSaved: action.payload.saved,
      };

    default:
      return { ...state };
  }
};
