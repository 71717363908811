import React from 'react';
import HomeView from './HomeView';
import useHomeViewModel from './HomeViewModel';

const Home: React.FC = () => {
  const viewModel = useHomeViewModel();

  return <HomeView {...viewModel} />;
};

export default Home;
