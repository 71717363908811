const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      fontSize: '18px',
    },
  },

  sizes: {
    md: {
      field: {
        borderRadius: 0,
      },
    },
    lg: {
      field: {
        w: '29rem',
        h: '4rem',
        borderRadius: 0,
      },
    },
  },

  variants: {
    filled: {
      field: {
        padding: 6,
        border: '1px solid',
        borderColor: 'black',
        textColor: 'black',
        bg: 'white',
        _focus: {
          bg: 'white',
        },
        _hover: {
          bg: 'gray.100',
        },
      },
    },
    flushed: {
      field: {
        _focus: {
          borderColor: 'blue.400',
        },
        borderColor: 'blue.400',
      },
    },
  },

  defaultProps: {
    variant: 'flushed',
  },
};

export default Input;
