import { Flex, FlexProps, Text } from '@chakra-ui/react';
import React from 'react';

const UPLOAD_STYLE = 'UPLOAD_STYLE';
const DOWNLOAD_STYLE = 'DOWNLOAD_STYLE';

type CardStyle = typeof UPLOAD_STYLE | typeof DOWNLOAD_STYLE;

type IHierarchyActionCard = FlexProps & {
  title: string;
  type: CardStyle;
};

const HierarchyActionCard: React.FC<IHierarchyActionCard> = ({
  title,
  children,
  type,
  ...props
}) => {
  return (
    <Flex
      flexDir="column"
      padding={6}
      bg="white"
      w="25%"
      h="24.3rem"
      border="1px"
      borderColor="gray.300"
      alignItems="center"
      {...props}
    >
      <Text fontSize="22px" fontWeight="700" color="blue.500" marginBottom={5}>
        {title}
      </Text>
      <Flex flexDir="column" h="100%" w="100%" justifyContent="space-between">
        {children}
      </Flex>
    </Flex>
  );
};

export default HierarchyActionCard;
