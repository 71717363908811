/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { INetworkResponse } from 'interfaces/index';
import { IUserState } from 'reducers/userReducer';
import { login } from 'services/User';
import { IUserLogin } from 'interfaces/UserInterfaces';
import { ILoginFormNetwork } from './interface';

const emptyPayload = {
  savedReports: [],
  auth: '',
  refreshToken: '',
  token: '',
  user: '',
};

const handleLogin: (
  userId: string,
  password: string
) => Promise<INetworkResponse<IUserState>> = async (
  userId,
  password
): Promise<INetworkResponse<IUserState>> => {
  try {
    const userData = await login(userId, password);
    if (userData.data.success) {
      return {
        success: true,
        statusCode: 200,
        errorMessage: '',
        payload: {
          token: '',
          refreshToken: '',
          user: userId,
          auth: '',
        },
      };
    }
    return {
      success: false,
      statusCode: 500,
      errorMessage: userData.data.errorMessage,
      payload: {
        token: '',
        refreshToken: '',
        user: userId,
        auth: '',
      },
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.code,
      payload: emptyPayload,
      errorMessage: 'Generic error!',
    };
  }
};

const LoginFormNetwork: ILoginFormNetwork = {
  handleLogin,
};

export default LoginFormNetwork;
