import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

interface INavbarViewModel {
  onLoadReport: () => void;
  onCustomReport: () => void;
  onLoadHierarchy: () => void;
}

const useNavbarViewModel: () => INavbarViewModel = () => {
  const history = useHistory();

  const navigateIfNecessary = useCallback((path: string) => {
    if (!(history.location.pathname === path)) {
      history.push(path);
    }
  }, []);

  const onLoadReport = useCallback(() => {
    navigateIfNecessary('/home');
  }, []);

  const onCustomReport = useCallback(() => {
    navigateIfNecessary('/build');
  }, []);

  const onLoadHierarchy = useCallback(() => {
    navigateIfNecessary('/hierarchy');
  }, []);

  return { onLoadReport, onCustomReport, onLoadHierarchy };
};

export default useNavbarViewModel;
