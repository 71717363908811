import {
  IAudienceDetailItem,
  IParameterDetailItem,
  IPDFReport,
  ISavedReport,
  ISavedReportModel,
} from 'interfaces/ReportInterfaces';
import { AxiosResponse } from 'axios';
import API from './api';

export const getParameterDetailItems = (
  parameter: string
): Promise<AxiosResponse<IParameterDetailItem[]>> => {
  return API.get(`/parameter-detail-items/${parameter}`);
};

export const getAudienceDetailItems = (
  audience: string
): Promise<AxiosResponse<IAudienceDetailItem[]>> => {
  return API.get(`/audience-detail-items/${audience}`);
};

export const getAdhocSummary = (
  report: ISavedReportModel
): Promise<AxiosResponse<IPDFReport>> => {
  return API.post('/reports/adhoc/summary', report);
};

export const getAdhocDetail = (
  report: ISavedReportModel
): Promise<AxiosResponse<IPDFReport>> => {
  return API.post(`/reports/adhoc/detail`, report);
};

export const saveReport = (
  report: ISavedReportModel
): Promise<AxiosResponse> => {
  return API.post('/saved-report', report);
};

export const getSavedReports = (
  userId: string
): Promise<AxiosResponse<ISavedReportModel[]>> => {
  return API.get(`/saved-report/all/${userId}`);
};

export const deleteSavedReport = (id: number): Promise<AxiosResponse> => {
  return API.delete(`/saved-report/${id}`);
};

export const getSavedReportById = (
  id: number
): Promise<AxiosResponse<ISavedReportModel>> => {
  return API.get(`/saved-report/${id}`);
};
