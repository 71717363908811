const Button = {
  baseStyle: {
    borderRadius: 0,
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
  },
  sizes: {
    xs: {
      minW: 6,
      h: '2.25rem',
      fontSize: '14px',
    },
    sm: {
      minW: '10.44rem',
      h: '2.25rem',
      fontSize: '14px',
    },
    md: {
      minW: '14rem',
      h: '3rem',
      fontSize: '22px',
      letterSpacing: '-0.02em',
    },
  },
  variants: {
    outline: {
      borderColor: 'blue.400',
      textColor: 'blue.400',
      letterSpacing: '0.02em',
    },
    tag: {
      border: '1px',
      borderColor: 'blue.400',
      textColor: 'blue.400',
      borderRadius: 'full',
      h: '2.25rem',
      minW: '9.125rem',
      minH: '2.25rem',
      fontSize: '14px',
      lineHeight: '18.33px',
      letterSpacing: '0.02em',
      padding: 4,
      _hover: { bg: 'blue.50' },
    },
    tagAudience: {
      border: '1px',
      borderColor: 'blue',
      textColor: 'blue',
      borderRadius: 'full',
      h: '2.25rem',
      minW: '9.125rem',
      minH: '2.25rem',
      fontSize: '14px',
      lineHeight: '18.33px',
      letterSpacing: '0.02em',
      padding: 4,
      _hover: { bg: 'blue.50' },
    },
    solid: {
      bg: 'blue.400',
      _disabled: {
        opacity: 1,
        bg: 'gray.500',
      },
    },
    ghost: {
      color: 'blue.400',
    },
  },
  defaultProps: {
    colorScheme: 'blue',
  },
};

export default Button;
