import React, { memo, useCallback, useMemo } from 'react';
import { Button, Text, Flex, Divider } from '@chakra-ui/react';
import Card from 'components/Card';
import { toNumber } from 'lodash';

import {
  Audiences,
  ISavedReportOutput,
  Parameters,
} from 'interfaces/ReportInterfaces';
import FilterField from 'components/FilterField';
import { AUDIENCE, PARAMETER } from 'actions/buildReportActions';
import useBuildReportViewModel from './BuildReportViewModel';

interface IOutputCard {
  index: number;
  output: ISavedReportOutput;
}

const OutputCard: React.FC<IOutputCard> = ({ index, output }) => {
  const {
    handleRemoveOutput,
    handleAddAudienceTag,
    handleAddParameterTag,
    handleRemoveAudienceTag,
    handleRemoveParameterTag,
    handleChangeAudience,
    handleChangeParameter,
  } = useBuildReportViewModel();

  const removeButton = useMemo(
    () => (
      <Button
        size="sm"
        variant="outline"
        textColor="gray.400"
        borderColor="gray.400"
        onClick={() => handleRemoveOutput(index)}
      >
        REMOVE
      </Button>
    ),
    [index]
  );

  const onSelectAudience = useCallback(
    (tag: string) => handleAddAudienceTag(index, tag),
    [index]
  );

  const onRemoveAudience = useCallback(
    (tagIndex: number) => handleRemoveAudienceTag(index, tagIndex),
    [index]
  );

  const onSelectParameter = useCallback(
    (tag: string) => handleAddParameterTag(index, tag),
    [index]
  );

  const onRemoveParameter = useCallback(
    (tagIndex: number) => handleRemoveParameterTag(index, tagIndex),
    [index]
  );

  return (
    <Card
      title={`OUTPUT #${index + 1}`}
      leftAddon={index !== 0 && removeButton}
    >
      <FilterField
        type={AUDIENCE}
        options={Audiences}
        tagLabel={output.Audience}
        tagList={output.SelectedAudienceDetail}
        handleChange={(e) =>
          handleChangeAudience(index, toNumber(e.target.value))
        }
        onSelectTag={onSelectAudience}
        onRemoveTag={onRemoveAudience}
        outputIndex={index}
      />
      <Divider marginTop={8} marginBottom={6} w="100%" />
      <FilterField
        type={PARAMETER}
        options={Parameters}
        tagLabel={output.Parameter}
        tagList={output.SelectedParameterDetail}
        handleChange={(e) =>
          handleChangeParameter(index, toNumber(e.target.value))
        }
        onSelectTag={onSelectParameter}
        onRemoveTag={onRemoveParameter}
        outputIndex={index}
      />
    </Card>
  );
};

export default memo(OutputCard);
