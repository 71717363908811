import { useBuildReportContext } from 'contexts/buildReportContext';
import OutputCard from 'pages/BuildReport/OutputCard';
import React from 'react';

const OutputList: React.FC = () => {
  const { state } = useBuildReportContext();
  const { currentReport } = state;
  const { Outputs } = currentReport;

  return (
    <>
      {Outputs.map((output, index) => (
        <OutputCard
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          output={output}
          index={index}
        />
      ))}
    </>
  );
};

export default OutputList;
