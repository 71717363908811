/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IHierarchyItem } from 'interfaces/HierarchyInterfaces';
import { INetworkResponse } from 'interfaces/index';
import { downloadHierarchy, uploadHierarchy } from 'services/Hierarchy';
import { IHierarchyNetwork } from './interfaces';

const handleUpload = async (
  items: IHierarchyItem[]
): Promise<INetworkResponse<undefined>> => {
  try {
    const response = await uploadHierarchy(items);

    if (response.data.success) {
      return {
        success: true,
        statusCode: 200,
        errorMessage: '',
        payload: undefined,
      };
    }
    return {
      success: false,
      statusCode: 500,
      errorMessage: response.statusText,
      payload: undefined,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.code,
      payload: undefined,
      errorMessage: 'Generic error!',
    };
  }
};

const handleDownload = async (): Promise<
  INetworkResponse<IHierarchyItem[]>
> => {
  try {
    const response = await downloadHierarchy();

    return {
      success: true,
      statusCode: response.status,
      errorMessage: '',
      payload: response.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.code,
      payload: [],
      errorMessage: 'Generic error!',
    };
  }
};

const HierarchyNetwork: IHierarchyNetwork = {
  handleUpload,
  handleDownload,
};

export default HierarchyNetwork;
