import { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyleControl: SystemStyleObject = {
  border: '1px solid',
  borderRadius: 'none',
  color: 'black',
  bg: 'transparent',

  _checked: {
    bg: 'transparent',
    borderColor: 'black',
    color: 'black',

    _hover: {
      bg: 'transparent',
      borderColor: 'black',
    },
  },

  _indeterminate: {
    borderColor: 'black',
    color: 'black',
  },
};

const Checkbox = {
  parts: ['control', 'icon', 'container', 'label'],
  baseStyle: {
    control: baseStyleControl,
  },
};

export default Checkbox;
