import React from 'react';
import { Table, Flex, Tbody } from '@chakra-ui/react';
import { IPDFTable } from 'interfaces/ReportInterfaces';
import PDFTableHeader from './PDFTableHeader';
import PDFRow from './PDFTableRow';
import PDFTableDescription from './PDFTableDescription';

// TODO: type table, header, row and columns style better (using chakraui)
const PDFTable: React.FC<IPDFTable> = ({ tableData, tableDescription }) => {
  return (
    <Flex
      flex="1"
      backgroundColor="#FFF"
      border="solid 2px black"
      direction="column"
      marginTop="10px"
      padding="5px"
    >
      <PDFTableDescription {...tableDescription} />
      <Table variant="unstyled" colorScheme="teal">
        <PDFTableHeader titles={tableData.headers} />
        <Tbody>
          {tableData.rows.map((rs) => (
            <PDFRow rows={rs} key={rs.toString()} />
          ))}
        </Tbody>
      </Table>
    </Flex>
  );
};

export default PDFTable;
