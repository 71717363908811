import { IDownloadModalPayload } from 'reducers/modalReducer';

export const OPEN_DMODAL = 'downloadModal/open';
export const CLOSE_DMODAL = 'downloadModal/close';
export const START_LOADING = 'downloadModal/start_loading';
export const FINISH_LOADING = 'downloadModal/finish_loading';

export interface IOpenDModal {
  readonly type: typeof OPEN_DMODAL;
  payload: IDownloadModalPayload;
}
export interface ICloseDModal {
  readonly type: typeof CLOSE_DMODAL;
}

export interface IStartLoadingModal {
  readonly type: typeof START_LOADING;
}

export interface IFinishLoadingModal {
  readonly type: typeof FINISH_LOADING;
}

export type DownloadModalAction =
  | IOpenDModal
  | ICloseDModal
  | IStartLoadingModal
  | IFinishLoadingModal;

export const openDownloadModal = (
  payload: IDownloadModalPayload
): IOpenDModal => ({
  type: OPEN_DMODAL,
  payload,
});

export const closeDownloadModal = (): ICloseDModal => ({ type: CLOSE_DMODAL });

export const startLoadingModal = (): IStartLoadingModal => ({
  type: START_LOADING,
});
export const finishLoadingModal = (): IFinishLoadingModal => ({
  type: FINISH_LOADING,
});
