import {
  PREDEFINED_REPORT,
  SUMMARY,
  URLTypes,
  IPDFReport,
} from 'interfaces/ReportInterfaces';
import { AxiosResponse } from 'axios';
import API from './api';

const getUrlByType = (reportType: PREDEFINED_REPORT, urlType: URLTypes) => {
  switch (reportType) {
    case 'SSC-VIEW-COUNT':
      return urlType === SUMMARY
        ? 'ssc-view-count-canned-summary'
        : 'ssc-view-count-canned-detail';
    case 'INACTIVE-USERS':
      return urlType === SUMMARY
        ? 'inactive-users-canned-summary'
        : 'inactive-users-canned-detail';
    case 'DEVICE-ACTIVATION':
      return urlType === SUMMARY
        ? 'device-activation-canned-summary'
        : 'device-activation-canned-detail';
    case 'DISCREET-SESSION':
      return urlType === SUMMARY
        ? 'discreet-sessions-count-canned-summary'
        : 'discreet-sessions-count-canned-detail';
    case 'USAGE-PER-TAB':
      return urlType === SUMMARY
        ? 'tab-view-time-canned-summary'
        : 'tab-view-time-canned-detail';
    case 'TBD':
      return urlType === SUMMARY ? 'tbd-canned-summary' : 'tbd-canned-detail';
    default:
      return '';
  }
};

const CannedReports = (
  reportType: PREDEFINED_REPORT,
  urlType: URLTypes
): Promise<AxiosResponse<IPDFReport>> => {
  return API.get(`/reports/canned/${getUrlByType(reportType, urlType)}`);
};

export default CannedReports;
