import { Button, Divider, Flex, Text } from '@chakra-ui/react';
import Footer from 'components/Footer';
import Header from 'components/Header';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IHomeViewModel } from './interfaces';
import ReportPresetsCard from './ReportPresetsCard';

const HomeView: React.FC<IHomeViewModel> = ({
  presets,
  openDModal,
  savedPresets,
  savedError,
  removeSavedPreset,
  loading,
  onBuildCustomReport,
}) => {
  const { t } = useTranslation('home');

  return (
    <Flex flex="1" bg="blue.100" flexDir="column">
      <Header title={t('title')} />
      <Flex w="100%" alignItems="center" justifyContent="center">
        <ReportPresetsCard
          title={t('predefinedReports')}
          cardStyle="PREDEFINED_STYLE"
          presets={presets}
          onDelete={() => {
            console.log('Delete');
          }}
          marginRight={6}
          onOpen={openDModal}
        />

        <ReportPresetsCard
          title={t('savedReports')}
          cardStyle="SAVED_STYLE"
          presets={savedPresets}
          errorMessage={savedError}
          onDelete={removeSavedPreset}
          loading={loading}
          onOpen={openDModal}
        />
      </Flex>
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        marginBlock={6}
      >
        <Divider w="3rem" borderColor="black" />
        <Text marginInline="0.875rem" fontSize="22px" fontWeight="bold">
          {t('or')}
        </Text>
        <Divider w="3rem" borderColor="black" />
      </Flex>

      <Button
        alignSelf="center"
        size="md"
        minH="4rem"
        colorScheme="blue"
        onClick={onBuildCustomReport}
      >
        {t('buildReportButton')}
      </Button>

      <Footer />
    </Flex>
  );
};

export default HomeView;
