import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enUS from './en';

const resources = {
  en: enUS,
};

i18n.use(initReactI18next).init({
  resources,
  defaultNS: 'common',
  lng: navigator.language,
  fallbackLng: 'en',
});

export default i18n;
