import { Divider, Flex, Icon } from '@chakra-ui/react';
import LinkText from 'components/LinkText';
import { IPresetItem, REPORT_PRESETS } from 'interfaces/ReportInterfaces';
import Reports from 'utils/ReportsUtils';
import React, { useMemo } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';

export interface IPresetListItem {
  id?: number;
  name: string;
  onDelete: (savedReport: IPresetItem) => void;
  onOpen: (
    preset: string,
    type: REPORT_PRESETS,
    id?: number | undefined
  ) => void;
  readonly type: REPORT_PRESETS;
}

const PresetItem: React.FC<IPresetListItem> = ({
  id,
  name,
  type,
  onDelete,
  onOpen,
}) => {
  const closeIcon = useMemo(
    () => (
      <Icon
        w="18px"
        h="18px"
        _hover={{ cursor: 'pointer' }}
        onClick={() => onDelete({ id, name, type })}
        as={IoMdCloseCircle}
      />
    ),
    []
  );

  return (
    <Flex flexDir="column">
      <LinkText
        addon={!Reports.isReportPresets(type) && closeIcon}
        decoration="none"
        fontSize="16px"
        color="gray.900"
        onClick={() => onOpen(name, type, id)}
      >
        {name}
      </LinkText>
      <Divider w="100%" marginTop={2} marginBottom={2} borderColor="gray.300" />
    </Flex>
  );
};

export default PresetItem;
