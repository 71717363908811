import {
  BuildReportAction,
  setUserId,
  addOutput,
  addTag,
  AUDIENCE,
  changeAudience,
  changeParameter,
  PARAMETER,
  removeOutput,
  removeTag,
  resetForm,
  setEndDate,
  setStartDate,
  setTimePeriod,
  setTitle,
} from 'actions/buildReportActions';
import { DownloadModalAction, openDownloadModal } from 'actions/modalActions';
import {
  ADHOC_REPORT,
  ISavedReportOutput,
  TimePeriodType,
} from 'interfaces/ReportInterfaces';
import { IBuildReportBusiness } from './interfaces';

const validateReport: (
  TimePeriod: TimePeriodType,
  StartDate: Date | null,
  EndDate: Date | null,
  Title: string,
  Outputs: ISavedReportOutput[]
) => boolean = (
  TimePeriod: TimePeriodType,
  StartDate: Date | null,
  EndDate: Date | null,
  Title: string,
  Outputs: ISavedReportOutput[]
) => {
  const periodValid =
    TimePeriod !== null && StartDate === null && EndDate === null;
  const dateRangeValid =
    StartDate !== null && EndDate !== null && TimePeriod === null;
  const titleValid = Title !== '';

  if ((periodValid || dateRangeValid) && titleValid) {
    for (let index = 0; index < Outputs.length; index += 1) {
      const element = Outputs[index];
      if (element.Audience === '' || element.Parameter === '') {
        return false;
      }
    }
  } else {
    return false;
  }

  return true;
};

const openBuildReportModal: (
  user: string,
  title: string,
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  openModalDispatch: React.Dispatch<DownloadModalAction>
) => void = (
  user: string,
  title: string,
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  openModalDispatch: React.Dispatch<DownloadModalAction>
) => {
  buildReportDispatch(setUserId(user));
  openModalDispatch(
    openDownloadModal({ presetName: title, presetType: ADHOC_REPORT })
  );
};

const resetReport: (
  buildReportDispatch: React.Dispatch<BuildReportAction>
) => void = (buildReportDispatch: React.Dispatch<BuildReportAction>) => {
  buildReportDispatch(resetForm());
};

const setThisYearTimePeriod: (
  buildReportDispatch: React.Dispatch<BuildReportAction>
) => void = (buildReportDispatch: React.Dispatch<BuildReportAction>) => {
  buildReportDispatch(setTimePeriod('thisYear'));
};
const setMonthToDateTimePeriod: (
  buildReportDispatch: React.Dispatch<BuildReportAction>
) => void = (buildReportDispatch: React.Dispatch<BuildReportAction>) => {
  buildReportDispatch(setTimePeriod('monthToDate'));
};
const setLast4WeeksTimePeriod: (
  buildReportDispatch: React.Dispatch<BuildReportAction>
) => void = (buildReportDispatch: React.Dispatch<BuildReportAction>) => {
  buildReportDispatch(setTimePeriod('last4Weeks'));
};
const setLast7DaysTimePeriod: (
  buildReportDispatch: React.Dispatch<BuildReportAction>
) => void = (buildReportDispatch: React.Dispatch<BuildReportAction>) => {
  buildReportDispatch(setTimePeriod('last7Days'));
};

const setReportStartDate: (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  date: Date
) => void = (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  date: Date
) => {
  buildReportDispatch(setStartDate(date));
};

const setReportEndDate: (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  date: Date
) => void = (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  date: Date
) => {
  buildReportDispatch(setEndDate(date));
};

const changeReportAudience: (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number,
  audienceIndex: number
) => void = (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number,
  audienceIndex: number
) => {
  buildReportDispatch(changeAudience(index, audienceIndex));
};

const changeReportParameter: (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number,
  parameterIndex: number
) => void = (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number,
  parameterIndex: number
) => {
  buildReportDispatch(changeParameter(index, parameterIndex));
};

const removeReportParameterTag: (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number,
  tagIndex: number
) => void = (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number,
  tagIndex: number
) => {
  buildReportDispatch(removeTag(index, PARAMETER, tagIndex));
};

const removeReportAudienceTag: (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number,
  tagIndex: number
) => void = (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number,
  tagIndex: number
) => {
  buildReportDispatch(removeTag(index, AUDIENCE, tagIndex));
};

const addReportParameterTag: (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number,
  tag: string
) => void = (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number,
  tag: string
) => {
  buildReportDispatch(addTag(index, PARAMETER, tag));
};

const addReportAudienceTag: (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number,
  tag: string
) => void = (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number,
  tag: string
) => {
  buildReportDispatch(addTag(index, AUDIENCE, tag));
};

const removeReportOutput: (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number
) => void = (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  index: number
) => {
  buildReportDispatch(removeOutput(index));
};

const addReportOutput: (
  buildReportDispatch: React.Dispatch<BuildReportAction>
) => void = (buildReportDispatch: React.Dispatch<BuildReportAction>) => {
  buildReportDispatch(addOutput());
};

const setReportTitle: (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  title: string
) => void = (
  buildReportDispatch: React.Dispatch<BuildReportAction>,
  title: string
) => {
  buildReportDispatch(setTitle(title));
};

const BuildReportBusiness: IBuildReportBusiness = {
  validateReport,
  openBuildReportModal,
  resetReport,
  setMonthToDateTimePeriod,
  setThisYearTimePeriod,
  setLast4WeeksTimePeriod,
  setLast7DaysTimePeriod,
  setReportStartDate,
  setReportEndDate,
  changeReportAudience,
  changeReportParameter,
  removeReportParameterTag,
  removeReportAudienceTag,
  addReportParameterTag,
  addReportAudienceTag,
  removeReportOutput,
  addReportOutput,
  setReportTitle,
};

export default BuildReportBusiness;
