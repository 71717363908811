import {
  PREDEFINED_REPORT,
  URLTypes,
  IPDFReport,
  ISavedReport,
  ISavedReportModel,
  ADHOC_REPORT,
  CANNED_REPORT,
} from 'interfaces/ReportInterfaces';
import {
  getAdhocDetail,
  getAdhocSummary,
  getSavedReportById,
  saveReport,
} from 'services/AdHocReports';

import CannedReports from 'services/CannedReports';
import Reports from 'utils/ReportsUtils';
import { format } from 'date-fns';
import JsPDF, { jsPDFOptions } from 'jspdf';

interface IResponseData {
  data?: IPDFReport;
  success: boolean;
}
interface IDownloadModalBusiness {
  getCannedReportData: (
    reportType: PREDEFINED_REPORT,
    urlType: URLTypes
  ) => Promise<IResponseData>;
  generatePDF: (title: string) => void;
  getAdhocDetailReport: (report: ISavedReport) => Promise<IResponseData>;
  getAdhocSummaryReport: (report: ISavedReport) => Promise<IResponseData>;
  saveAdhocReport: (report: ISavedReport) => Promise<void>;
  getSummarySavedReport: (report: ISavedReportModel) => Promise<IResponseData>;
  getDetailSavedReport: (report: ISavedReportModel) => Promise<IResponseData>;
  getSavedReportByIdReport: (id: number) => Promise<ISavedReportModel>;
}

const DownloadModalBusiness = (): IDownloadModalBusiness => {
  const generatePDF = async (title: string): Promise<void> => {
    const defaultOptions: jsPDFOptions = {
      precision: 1,
      unit: 'px',
      orientation: 'portrait',
      compress: true,
    };
    const doc = new JsPDF({ ...defaultOptions });
    const htmlText = document.getElementById('myReport') ?? ({} as HTMLElement);

    await doc.html(htmlText ?? '');
    const date = new Date();
    const dateStr = format(date, 'MM_dd_yy');
    doc.save(`${title}_${dateStr}.pdf`);
    // Fixes page breaking in graphs
    doc.addPage();
  };

  const getCannedReportData = async (
    reportType: PREDEFINED_REPORT,
    urlType: URLTypes
  ) => {
    try {
      const response = await CannedReports(reportType, urlType);

      return {
        data: {
          ...response.data,
          reportType: CANNED_REPORT,
        },
        success: true,
      };
    } catch (error) {
      return { success: false };
    }
  };

  const getAdhocSummaryReport = async (report: ISavedReport) => {
    try {
      const model = Reports.savedReportToModel(report);
      const response = await getAdhocSummary(model);

      return {
        data: {
          ...response.data,
          reportType: ADHOC_REPORT,
        },
        success: true,
      };
    } catch (error) {
      return { success: false };
    }
  };

  const getAdhocDetailReport = async (
    report: ISavedReport
  ): Promise<IResponseData> => {
    try {
      const model = Reports.savedReportToModel(report);
      const response = await getAdhocDetail(model);

      return {
        data: {
          ...response.data,
          reportType: ADHOC_REPORT,
        },
        success: true,
      };
    } catch (error) {
      return { success: false };
    }
  };
  const getSummarySavedReport = async (report: ISavedReportModel) => {
    try {
      const response = await getAdhocSummary(report);

      return {
        data: {
          ...response.data,
          reportType: ADHOC_REPORT,
        },
        success: true,
      };
    } catch (error) {
      return { success: false };
    }
  };

  const getDetailSavedReport = async (
    report: ISavedReportModel
  ): Promise<IResponseData> => {
    try {
      const response = await getAdhocDetail(report);

      return {
        data: {
          ...response.data,
          reportType: ADHOC_REPORT,
        },
        success: true,
      };
    } catch (error) {
      return { success: false };
    }
  };

  const saveAdhocReport = async (report: ISavedReport): Promise<void> => {
    const model = Reports.savedReportToModel(report);

    const response = await saveReport(model);

    return response.data;
  };

  const getSavedReportByIdReport = async (
    id: number
  ): Promise<ISavedReportModel> => {
    const response = await getSavedReportById(id);

    return response.data;
  };

  return {
    getCannedReportData,
    generatePDF,
    getAdhocDetailReport,
    getAdhocSummaryReport,
    saveAdhocReport,
    getSummarySavedReport,
    getDetailSavedReport,
    getSavedReportByIdReport,
  };
};

export default DownloadModalBusiness;
