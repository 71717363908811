import { Flex, Text } from '@chakra-ui/react';
import SingleDatepicker from 'components/DatePicker';
import React, { memo } from 'react';

interface IRangeDatePicker {
  startDate: Date | null;
  handleStartDateChange: (date: Date) => void;
  endDate: Date | null;
  handleEndDateChange: (date: Date) => void;
}

const RangeDatePicker: React.FC<IRangeDatePicker> = ({
  startDate,
  handleStartDateChange,
  endDate,
  handleEndDateChange,
}) => {
  return (
    <Flex alignItems="center">
      <SingleDatepicker
        placeholder="Start date"
        maxDate={endDate || undefined}
        value={startDate}
        onChange={handleStartDateChange}
      />
      <Text marginLeft="2" marginRight="2" textAlign="center">
        -
      </Text>
      <SingleDatepicker
        placeholder="End date"
        value={endDate}
        onChange={handleEndDateChange}
      />
    </Flex>
  );
};

export default memo(RangeDatePicker);
