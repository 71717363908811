import { initialState, IUserState, userReducer } from 'reducers/userReducer';
import React, { createContext, useContext, useReducer } from 'react';
import { IUserContext } from './interface';

const UserContext = createContext<IUserContext>({
  state: initialState(),
  dispatch: () => undefined,
});

interface IUserProvider {
  state?: IUserState;
}

const UserProvider: React.FC<IUserProvider> = ({ children, state }) => {
  const [userState, userDispatch] = useReducer(userReducer, {
    ...initialState(),
    ...state,
  });

  return (
    <UserContext.Provider
      value={{
        state: userState,
        dispatch: userDispatch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.defaultProps = {
  state: undefined,
};

export const useUserContext = (): IUserContext => useContext(UserContext);

export default UserProvider;
