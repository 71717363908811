import { Flex, Input, InputProps, useMultiStyleConfig } from '@chakra-ui/react';
import React, { useRef } from 'react';
import Button from 'styles/components/Button';

const FileInput: React.FC<InputProps> = (props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const styles = useMultiStyleConfig('Button', { pointerEvents: 'none' });

  return (
    <Flex
      _hover={{ cursor: 'pointer' }}
      onClick={() => inputRef.current?.click()}
    >
      <Input
        type="file"
        styleConfig={Button}
        sx={{
          '::file-selector-button': {
            border: 'none',
            outline: 'none',
            mr: 2,
            ...styles,
          },
          width: '100%',
        }}
        pointerEvents="none"
        {...props}
        ref={inputRef}
      />
    </Flex>
  );
};

export default FileInput;
