/* eslint-disable no-nested-ternary */
import { Flex, FlexProps, Text, Spinner } from '@chakra-ui/react';
import React, { memo } from 'react';
import { IPresetItem } from 'interfaces/ReportInterfaces';
import { arraysEqual } from 'utils/ComparisonFunctions';
import ConditionalRenderer from 'components/ConditionalRenderer';
import PresetList, { IPresetList } from './PresetList';

const PREDEFINED_STYLE = 'PREDEFINED_STYLE';
const SAVED_STYLE = 'SAVED_STYLE';

type CardStyle = typeof PREDEFINED_STYLE | typeof SAVED_STYLE;

type IReportPresetsCard = FlexProps &
  IPresetList & {
    loading?: boolean;
    onDelete: (savedReport: IPresetItem) => void;
    title: string;
    readonly cardStyle: CardStyle;
  };

const ReportPresetsCard: React.FC<IReportPresetsCard> = ({
  title,
  cardStyle,
  presets,
  errorMessage,
  onDelete,
  onOpen,
  loading = false,
  ...props
}) => {
  return (
    <Flex
      flexDir="column"
      padding={6}
      bg="white"
      w="22.5rem"
      h="24.3rem"
      border="1px"
      borderColor="gray.300"
      {...props}
    >
      <Text
        fontSize="22px"
        fontWeight="700"
        color={cardStyle === PREDEFINED_STYLE ? 'blue.500' : 'blue.300'}
        marginBottom={5}
      >
        {title}
      </Text>
      <Flex
        flex="1"
        flexDir="column"
        justifyContent={
          cardStyle === PREDEFINED_STYLE ? 'space-evenly' : 'flex-start'
        }
        overflowY="auto"
        paddingRight={4}
        css={{
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7f7f7f',
            borderRadius: '24px',
            width: '8px',
          },
        }}
      >
        <ConditionalRenderer
          condition={loading}
          positiveComponent={
            <Flex flex="1" justifyContent="center" alignItems="center">
              <Spinner />
            </Flex>
          }
          negativeComponent={
            <PresetList
              onOpen={onOpen}
              onDelete={onDelete}
              presets={presets}
              errorMessage={errorMessage}
            />
          }
        />
      </Flex>
    </Flex>
  );
};

export default memo<IReportPresetsCard>(
  ReportPresetsCard,
  (prevProps, nextProps) =>
    arraysEqual(prevProps.presets, nextProps.presets) &&
    prevProps.loading === nextProps.loading &&
    prevProps.errorMessage === nextProps.errorMessage
);
