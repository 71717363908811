import { IS_ATTR } from 'utils/ReportsUtils';

export interface IPDFTableDescription {
  title: string;
  group: string;
  dateFrom: string;
  dateTo: string;
  totalTitle: string;
  dateRange: string;
  totalValue: string;
}

export interface PDFTableData {
  headers: string[];
  rows: string[][];
}
export interface IPDFTable {
  tableDescription: IPDFTableDescription;
  tableData: PDFTableData;
}

export interface IPDFReport {
  id?: string;
  generatedAt: string;
  timeFrameFrom: string;
  timeFrameTo: string;
  reportTitle: string;
  reportAboutText: string;
  reportSubtitle: string;
  reportIncludedItems: string[];
  reportTableData: IPDFTable[];
  showChart?: boolean;
  reportType: string;
}

export interface ITBDGraphData {
  [label: string]: Map<string, string>;
}

export interface ITBDGraph {
  title: string;
  labels: Set<string>;
  sets: Set<string>;
  graph: ITBDGraphData;
}

export const SSC_VIEW_COUNT = 'SSC-VIEW-COUNT';
export const INACTIVE_USERS = 'INACTIVE-USERS';
export const DEVICE_ACTIVATION = 'DEVICE-ACTIVATION';
export const DISCREET_SESSION = 'DISCREET-SESSION';
export const USAGE_PER_TAB = 'USAGE-PER-TAB';
export const TBD = 'TBD';

export const cannedReports = [
  SSC_VIEW_COUNT,
  INACTIVE_USERS,
  DEVICE_ACTIVATION,
  DISCREET_SESSION,
  USAGE_PER_TAB,
  TBD,
] as const;

export type PREDEFINED_REPORT = typeof cannedReports[number];
export const SAVED_REPORT = 'reports/saved';
export const ADHOC_REPORT = 'reports/adhoc';
export const CANNED_REPORT = 'reports/canned';
export type REPORT_PRESETS =
  | PREDEFINED_REPORT
  | typeof SAVED_REPORT
  | typeof ADHOC_REPORT;

export const SUMMARY = 'SUMMARY';
export const DETAIL = 'DETAIL';

export type URLTypes = typeof SUMMARY | typeof DETAIL;

export interface IPresetItem {
  id?: number;
  name: string;
  type: REPORT_PRESETS;
}

export const Audiences = ['GM', 'User', IS_ATTR ? '' : 'Retailer'] as const;

export const Parameters = [
  '',
  'Device Activation',
  'App Usage Time',
  'Tab View Count',
  'Tab View Time',
  'Video View Count',
  'Discreet Session Count',
  'SSC Item View Count',
  'SSC View Count',
] as const;

export const TimePresets = [
  null,
  'last7Days',
  'last4Weeks',
  'monthToDate',
  'thisYear',
] as const;

export type AudiencesType = typeof Audiences[number];
export type ParametersType = typeof Parameters[number];
export type TimePeriodType = typeof TimePresets[number];

export interface ISavedReportOutput {
  Audience: AudiencesType;
  AudienceDetail: string[];
  SelectedAudienceDetail: string[];
  Parameter: ParametersType;
  ParameterDetail: string[];
  SelectedParameterDetail: string[];
}

export interface ISavedReport {
  Id?: number;
  UserId: string;
  Title: string;
  TimePeriod: TimePeriodType;
  StartDate: Date | null;
  EndDate: Date | null;
  Outputs: ISavedReportOutput[];
}

export interface IOutputModel {
  Audience: string;
  AudienceDetail: string | null;
  Parameter: string;
  ParameterDetail: string | null;
}
export interface ISavedReportModel {
  Id?: number;
  UserId: string;
  Title: string;
  TimePeriod: string | null;
  StartDate: string | null;
  EndDate: string | null;
  Outputs: IOutputModel[];
}

export interface IParameterDetailItem {
  ParameterDetail: string;
}

export interface IAudienceDetailItem {
  AudienceDetail: string;
}
